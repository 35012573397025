(function(app) {
    app.controller('CallsUnassignedCtrl', [
        '$scope', 'api', 'SweetAlert', 'alerts',
        function($scope, api, SweetAlert, alerts) {
            $scope.getUnassignedCalls = function() {
                api
                    .getUnassignedCalls()
                    .then(function(response) {
                        $scope.unassigned_calls = response.calls;
                        $scope.displayed = $scope.unassigned_calls; // Ensure displayed is also updated
                    })
                    .catch(function(response) {
                        SweetAlert.swal('Error', response, 'error');
                    });
            };
            
            $scope.formatDate = function(date) {
                return moment(date).format('MM/DD/YYYY');
            };
            $scope.getStatus = function(status) {
                const statusMap = {
                    1: 'New',
                    2: 'In Progress',
                    // 3: '(Left Message)',
                    4: 'Closed',
                    // 5: '(Called Back)',
                    // 6: '(Could Not Contact)'
                };
                return statusMap[status] || '';
            };
            // New function to pretty print the contact value
            $scope.getContact = function (contact) {
                const contactMap = {
                    1: '(Phone Call)',
                    2: '(Sent Email)',
                    3: '(Left Voicemail)',
                    4: '(Unable to Contact)',
                    5: '(Other)'
                };
                return contactMap[contact] || '';
            };
            $scope.viewCall = function(call_data) {
                console.log('Fetching call data for ID:', call_data.id);
                api
                    .getCallById(call_data.id)
                    .then(function(response) {
                        console.log('Raw response received:', response);
                        if (!response || !response.call) {
                            throw new Error('No data received for call ID: ' + call_data.id);
                        }
                        console.log('Call data received:', response.call);
                        $scope.call_data = response.call;
            
                        // Check if $scope.call_data is properly assigned
                        if (!$scope.call_data) {
                            throw new Error('Failed to assign call data for call ID: ' + call_data.id);
                        }
            
                        // Check specific fields to ensure data integrity
                        if ($scope.call_data.volunteer_schedule_id === undefined) {
                            console.warn('Warning: volunteer_schedule_id is not defined for call ID:', call_data.id);
                        }
            
                        alerts.callRegister($scope.call_data, $scope.current_user.volunteer.id, function(updatedCallData) {
                            // No need to handle updatedCallData here as we will refresh the list
                        }, function() {
                            // Refresh the unassigned calls list when the modal is closed
                            $scope.getUnassignedCalls();
                        });
                    })
                    .catch(function(error) {
                        console.error('Error:', error);
                        SweetAlert.swal('Error', error.message || 'An error occurred while fetching the call data.', 'error');
                    });
            };
            
            $scope.unassigned_calls = [];
            $scope.displayed = [];
            $scope.my_time = [];
            $scope.getUnassignedCalls();
        }
    ]);
})(window.skeletonApp);
