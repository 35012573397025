'use strict';


(function (app)
{
    app.controller('EventRegisterCtrl', [
        '$scope', '$sce', 'api', 'SweetAlert', '$uibModalInstance', 'event_data', 'user_id','refresh_event_page',
        function ($scope, $sce, api, SweetAlert, $uibModalInstance, event_data, user_id, refresh_event_page)
        {
            $scope.event_data = {};

            $scope.getEventData = function()
            {
                api
                    .getEventById(event_data.id)
                    .then(function(results)
                    {
                        (results.event);
                        $scope.event_data = results.event;
                        $scope.event_data.date = moment($scope.event_data.date).format('MMMM D, h:mm A');
                        for(var i = 0; i < $scope.event_data.event_blocks.length; i++)
                        {
                            var event_block = $scope.event_data.event_blocks[i];
                            event_block.start_time = moment(event_block.start_time).format('h:mm A');
                            event_block.end_time = moment(event_block.end_time).format('h:mm A');
                            
                            if(event_block.volunteers_needed <= event_block.event_volunteers.length)
                                event_block.is_full = true;
                            for(var ii = 0; ii < event_block.event_volunteers.length; ii++)
                            {
                                if(event_block.event_volunteers[ii].volunteer_id == user_id)
                                {
                                    event_block.registered = true;
                                }
                            }
                            $scope.event_data.event_blocks[i] = event_block;
                        }
                        ($scope.event_data);
                    })
                    .catch(function(results)
                    {
                        SweetAlert.swal('Error', 'There was an error grabbing the event info!', 'error');
                        $uibModalInstance.dismiss();
                    });
            }
            $scope.getEventData();

            $scope.registerForThisEvent = function(event_block_id)
            {
                SweetAlert.swal(
                    {
                        title:'Are you sure?',
                        text:'Are you sure you want to volunteer for this time?',
                        showCancelButton:true,
                        closeOnConfirm:true,
                    },
                    function(confirm)
                    {
                        if(confirm)
                        {
                            api
                                .postRegisterForEvent(event_block_id)
                                .then(function(response)
                                {
                                    SweetAlert.swal('Success',response.okay, 'success');
                                    refresh_event_page();
                                    $scope.getEventData();
                                })
                                .catch(function(response)
                                {
                                    SweetAlert.swal('Error',response,'error');
                                });
                        }
                    }
                );

            };
            $scope.unregisterForThisEvent = function(event_block_id)
            {
                SweetAlert.swal(
                    {
                        title:'Are you sure?',
                        text:'Are you sure you want to un-volunteer for this time?',
                        showCancelButton:true,
                        closeOnConfirm:true,
                    },
                    function(confirm)
                    {
                        if(confirm)
                        {
                            api
                                .postUnregisterForEvent(event_block_id)
                                .then(function(response)
                                {
                                    SweetAlert.swal('Success',response.okay, 'success');
                                    refresh_event_page();
                                    $scope.getEventData();
                                })
                                .catch(function(response)
                                {
                                    SweetAlert.swal('Error',response,'error');
                                });
                        }
                    }
                );
            };
            
            $scope.dismiss = $uibModalInstance.dismiss;
        }
    ]);
})(window.skeletonApp);