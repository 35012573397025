(function(app) {
    app.controller('CallsOldCtrl', [
        '$scope', 'api', 'SweetAlert', 'alerts',
        function($scope, api, SweetAlert, alerts) {
            // Initialize variables
            $scope.completed_calls = [];
            $scope.displayed = [];
            $scope.is_loading = false;
            $scope.start_date = new Date(moment().subtract(3, 'days').format('MM/DD/YYYY'));
            $scope.end_date = new Date(moment().format('MM/DD/YYYY'));
            $scope.tablestate = null;

            // Watchers for start_date and end_date to trigger call fetching
            $scope.$watch('start_date', function() {
                $scope.getCompletedCalls($scope.tablestate);
            });

            $scope.$watch('end_date', function() {
                $scope.getCompletedCalls($scope.tablestate);
            });

            // Format date to a readable format
            $scope.formatDate = function(date) {
                return moment(date).format('MM/DD/YYYY');
            };

            // Get the contact type in a readable format
            $scope.getContact = function(contact) {
                const contactMap = {
                    '1': '(Phone Call)',
                    '2': '(Sent Email)',
                    '3': '(Left Voicemail)',
                    '4': '(Unable to Contact)',
                    '5': '(Other)'
                };
                return contactMap[contact] || '';
            };

            // Fetch completed calls based on the table state
            $scope.getCompletedCalls = function(tablestate) {
                if (!tablestate) return;

                $scope.is_loading = true;
                tablestate.search.start_date = moment($scope.start_date).format();
                tablestate.search.end_date = moment($scope.end_date).format();
                $scope.tablestate = tablestate;

                api.postCompletedVolunteerCalls(tablestate)
                    .then(function(response) {
                        $scope.is_loading = false;
                        $scope.displayed = [].concat(response.calls);
                        tablestate.pagination.numberOfPages = Math.ceil(response.total / tablestate.pagination.number);
                    })
                    .catch(function(response) {
                        $scope.is_loading = false;
                        SweetAlert.swal('Error', response, 'error');
                    });
            };

            // View call details and handle modal actions
            $scope.viewCall = function(call_data) {
                console.log('viewCall called with call_data:', call_data);
            
                api.getCallById(call_data.id)
                    .then(function(response) {
                        console.log('Full API Response:', response);
            
                        // Access the nested call object correctly
                        if (!response || !response.call || !response.call.id) {
                            SweetAlert.swal('Error', 'Failed to fetch call data. The response is missing necessary information.', 'error');
                            return;
                        }
            
                        $scope.call_data = response.call; // Extract the call data
                        console.log('Updated scope call_data before opening modal:', $scope.call_data);
            
                        // Open the modal in edit mode with the fetched call data
                        alerts.viewCall($scope.call_data, 
                            function(call_data, ui_model) { 
                                // Resolve the call 
                                api.postUpdateCall({
                                    call: {
                                        info: {
                                            id: call_data.id,
                                            completed_date: moment().format(),
                                            status: 'r',
                                            has_alert: false
                                        }
                                    }
                                }).then(function(results) {
                                    console.log('Call resolved:', results);
                                    $scope.getCompletedCalls($scope.tablestate);
                                    SweetAlert.swal('Success', 'The call has been marked as resolved!', 'success');
                                    ui_model.dismiss();
                                }).catch(function(error) {
                                    SweetAlert.swal('Error', error, 'error');
                                });
                            },
                            function(id, ui_model) { 
                                // Unvolunteer from call
                                api.postRemoveVolunteer(id).then(function() {
                                    console.log('Volunteer removed:', id);
                                    $scope.getCompletedCalls($scope.tablestate);
                                    ui_model.close();
                                }).catch(function(error) {
                                    SweetAlert.swal('Error', error, 'error');
                                });
                            },
                            function(call_data, ui_model) { 
                                // Update the call 
                                console.log('Updating call data:', call_data);
                                const completed_date = ['1', '2', '3'].indexOf(call_data.status) >= 0 ? '0000-00-00 00:00:00' : moment().format();
            
                                api.postUpdateCall({
                                    call: {
                                        info: {
                                            id: call_data.id,
                                            notes: call_data.notes,
                                            issue_description: call_data.issue_description,
                                            time_spent: call_data.time_spent,
                                            completed_date: completed_date,
                                            status: call_data.status,
                                            contact: call_data.contact,
                                            caller_name: call_data.caller_name,
                                            caller_phone: call_data.caller_phone,
                                            address: call_data.address,
                                            caller_city: call_data.caller_city,
                                            zip: call_data.zip,
                                            caller_county_id: call_data.caller_county_id,
                                            site_coordinator_id: call_data.site_coordinator_id
                                        }
                                    }
                                }).then(function(results) {
                                    console.log('Call updated:', results);
                                    $scope.getCompletedCalls($scope.tablestate);
                                    ui_model.close();
                                    SweetAlert.swal('Success', 'The call has been updated!', 'success');
                                }).catch(function(error) {
                                    SweetAlert.swal('Error', error, 'error');
                                });
                            },
                            function(ui_model) { 
                                // Handle modal close without saving 
                                console.log('Modal closed without saving');
                                ui_model.close();
                            }
                        );
                    })
                    .catch(function(response) {
                        SweetAlert.swal('Error', response, 'error');
                    });
            };
            
            // Initial call to fetch completed calls
            $scope.getCompletedCalls(); 
        }
    ]);
})(window.skeletonApp);
