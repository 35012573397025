'use strict';
(function (app)
{
    app.controller('HomeCtrl', [
        '$scope','api',
        function ($scope, api)
        {
        }
    ]);
})(window.skeletonApp);
