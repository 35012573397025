/**
 * Created by mwillard on 5/2/16.
 */
(function(app)
{
    app.controller('ReportCtrl', [
        '$scope','api','SweetAlert',
        function($scope, api, SweetAlert)
        {
            $scope.submitShip = function()
            {
                api
                    .getSHIPExport($scope.ship_data)
                    .then(function(response)
                    {
                        window.open(response.link, '_blank', '');
                    })
                    .catch(function(response)
                    {
                        SweetAlert.swal('Error', response, 'error');
                    });
            };
            $scope.ship_data =
            {
            };
            $scope.on_time_data =
            {
            };
            $scope.report_loaded = false;
            $scope.submitOnTime = function()
            {
                $scope.no_data_month = false;
                $scope.no_data_day = false;
                $scope.report_loaded = false;
                api
                    .getOnTimeReport({start_date:$scope.on_time_data.start_date, end_date:$scope.on_time_data.end_date})
                    .then(function(result)
                    {
                        var monthly_element = angular.element( document.querySelector( '#monthly_report' ) );
                        var daily_element = angular.element( document.querySelector( '#daily_report' ));
                        monthly_element.empty();
                        daily_element.empty();
                        var results = result.results;
                        $scope.report_loaded = true;
                        if(results.months.length)
                            new Morris.Line({
                                // ID of the element in which to draw the chart.
                                element: 'monthly_report',
                                // Chart data records -- each entry in this array corresponds to a point on
                                // the chart.
                                data:results.months,
                                // The name of the data record attribute that contains x-values.
                                xkey: 'month',
                                ymax:100,
                                // A list of names of data record attributes that contain y-values.
                                ykeys: ['value'],
                                parseTime:false,
                                labels: ['Percent On Time'],
                                resize:true
                            });
                        else
                        {
                            $scope.no_data_month = true;
                        }
                        if(results.days.length)
                            new Morris.Line({
                                // ID of the element in which to draw the chart.
                                element: 'daily_report',
                                // Chart data records -- each entry in this array corresponds to a point on
                                // the chart.
                                data:results.days,
                                // The name of the data record attribute that contains x-values.
                                xkey: 'day',
                                parseTime:false,
                                ymax:100,
                                parseTime:false,
                                // A list of names of data record attributes that contain y-values.
                                ykeys: ['value'],
                                labels: ['Percent On Time'],
                                resize:true
                            });
                        else
                            $scope.no_data_day = true;
                    })
                    .catch(function(result)
                    {
                        SweetAlert.swal('Error', 'Unable to generate the report!' + result, 'error');
                    });
            }
        }
    ])
})(window.skeletonApp);