'use strict';
(function (app)
{
    app.controller('TimeSetCtrl', [
        '$scope','api', 'SweetAlert', '$uibModalInstance', 'edit','volunteer_schedules','removeCallback','admin',
        function ($scope, api, SweetAlert, $uibModalInstance, edit, volunteer_schedules, removeCallback, admin)
        {
            $scope.edit = edit;
            $scope.removeCallback = removeCallback;
            $scope.admin = admin;
            if(volunteer_schedules != null)
            {
                $scope.volunteer_schedules =
                {
                    id:volunteer_schedules.id,
                    calls:volunteer_schedules.calls,
                    start_time:volunteer_schedules.start_time,
                    hours:volunteer_schedules.hours,
                    end_time:volunteer_schedules.end_time
                }
            }
            else {
                $scope.volunteer_schedules =
                {
                    start_time: new Date(moment().format("M/D/Y, h:mm A"))
                };
            }
            
            $scope.ok = function()
            {
                if(!Date.parse($scope.volunteer_schedules.start_time))
                {
                    $scope.errorMsg = "Start Time has not been filled in!";
                    return;
                }
                if($scope.volunteer_schedules.hours % .5 != 0) {
                    $scope.errorMsg = "Only half hour increments are allowed!";
                    return;
                }
                $scope.errorMsg = "";
                $uibModalInstance.close($scope.volunteer_schedules);
            };
            $scope.requestToRemove = function()
            {
                SweetAlert.swal(
                    {
                        title:'Are you sure?',
                        text:'This will send a request to your site coordinator. If accepted, this time will be removed',
                        type:'warning',
                        showCancelButton:true,
                    },
                    function(confirm)
                    {
                        if(confirm)
                            api
                                .postRequestToRemove({id: $scope.volunteer_schedules.id})
                                .then(function(response)
                                {
                                    SweetAlert.swal('Success', 'A request has been sent to your Site Coordinator!', 'success');
                                })
                                .catch(function(response)
                                {
                                   SweetAlert.swal('Error', response, 'error');
                                });
                    }
                )
            };
            $scope.dismiss = function(r)
            {
                $uibModalInstance.dismiss(r)
            };
            $scope.remove = function(r)
            {
                $scope.removeCallback($scope.volunteer_schedules, $uibModalInstance);
            };
            $scope.getEndTime = function()
            {
                return moment($scope.volunteer_schedules.start_time)
                    .add($scope.volunteer_schedules.hours,'h')
                    .format("M/D/Y, h:mm A");
            }
        }
    ]);
})(window.skeletonApp);