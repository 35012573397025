'use strict';

(function (app) {
    app.factory('api', [
        '$rootScope', '$http', '$location', '$q', 'alerts',
        function ($rootScope, $http, $location, $q, alerts) {
            var rootpoint = '/api';
            var apiFactory = {};

            apiFactory.isAuthenticated = function () {
                return doRequest('auth', 'authenticated', 'get');
            };

            apiFactory.sendAuthentication = function (username, password) {
                return doRequest('auth', 'authenticate', 'post', {
                    username: username,
                    password: password
                });
            };

            // User Info
            apiFactory.getCurrentUser = function () {
                return doRequest('user', 'current-user', 'get');
            };
            apiFactory.getUserList = function () {
                return doRequest('user', 'list', 'get');
            };
            apiFactory.getUserTypes = function () {
                return doRequest('user', 'user-types', 'get');
            };
            apiFactory.getSiteCoordinators = function () {
                return doRequest('user', 'site-coordinators', 'get');
            };
            apiFactory.getAllVolunteerUsers = function() {
                return doRequest('user', 'all-volunteer-users', 'get');
            };
            apiFactory.getUserById = function (id) {
                return doRequest('user', 'user-by-id', 'get', {id: id});
            };
            apiFactory.getAllUsers = function () {
                return doRequest('user', 'all-users', 'get');
            };
            apiFactory.postAddUser = function (data) {
                return doRequest('user', 'add', 'post', data);
            };
            apiFactory.postDeleteUser = function (data) {
                return doRequest('user', 'delete', 'post', data);
            };
            apiFactory.postUpdateUser = function (data) {
                return doRequest('user', 'update', 'post', data);
            };
            apiFactory.postUpdateEvent = function(data) {
                const payload = {
                    event: {
                        id: data.id, // Ensure the event ID is included
                        name: data.name,
                        date: data.date,
                        description: data.description,
                        location_name: data.location_name,
                        location_address: data.location_address,
                        county_id: data.county_id,
                        event_blocks: data.event_blocks
                    }
                };
                return doRequest('event', 'edit', 'post', payload);
            };
            
            apiFactory.postUserStatus = function (data) {
                return doRequest('user', 'user-status', 'post', data);
            };
            apiFactory.postSearchUsers = function (data) {
                return doRequest('user', 'search-users', 'post', data);
            };
            apiFactory.postChangeUserPassword = function (data) {
                return doRequest('user', 'change-password', 'post', data);
            };
            apiFactory.postCheckPhoneNumber = function(phoneNumber) {
                return doRequest('call', 'check-phone-number', 'post', { phone_number: phoneNumber });
            };            
            // Impersonation
            apiFactory.impersonateUser = function (userId) {
                return doRequest('user', 'impersonate', 'post', {user_id: userId});
            };
            apiFactory.stopImpersonating = function () {
                return doRequest('user', 'stop-impersonating', 'post');
            };
            apiFactory.getImpersonationState = function() {
                return doRequest('user', 'impersonation-state', 'get');
            };
            // Volunteer Info
            apiFactory.postAddVolunteerSchedule = function (data) {
                return doRequest('volunteer-schedule', 'add', 'post', data);
            };
            apiFactory.getScheduleByVolunteer = function (data) {
                return doRequest('volunteer-schedule', 'schedule-by-volunteer', 'get', data);
            };
            apiFactory.getFreeVolunteerSchedules = function (data) {
                return doRequest('volunteer-schedule', 'free-volunteer-schedules', 'get', data);
            };
            apiFactory.getScheduleBySubjects = function (data) {
                return doRequest('volunteer-schedule', 'schedule-by-subjects', 'get', data);
            };
            apiFactory.postUpdateVolunteerSchedule = function (data) {
                return doRequest('volunteer-schedule', 'update', 'post', data);
            };
            apiFactory.postDeleteVolunteerSchedule = function (data) {
                return doRequest('volunteer-schedule', 'delete', 'post', data);
            };
            apiFactory.postRequestToRemove = function(data) {
                return doRequest('volunteer-schedule', 'request-to-remove', 'post', data);
            };

            // Calls
            apiFactory.getCallById = function (id) {
                return doRequest('call', 'call-by-id', 'get', {id: id});
            };
            apiFactory.getAllCalls = function () {
                return doRequest('call', 'all-calls', 'get');
            };
            apiFactory.getAllCallsToday = function () {
                return doRequest('call', 'all-calls-today', 'get');
            };
            apiFactory.getCallDataByDates = function (data) {
                return doRequest('call', 'call-data-by-dates', 'get', data);
            };
            apiFactory.getCallByVolunteerId = function (id) {
                return doRequest('call', 'call-by-volunteer-id', 'get', {id: id});
            };
            apiFactory.postRemoveVolunteer = function(call_id) {
                return doRequest('call', 'remove-volunteer', 'post', {call_id: call_id});
            };
            apiFactory.postAddVolunteer = function(call_id, volunteer_id) {
                return doRequest('call', 'add-volunteer', 'post', {call_id: call_id, volunteer_id:volunteer_id});
            };
            apiFactory.postAddCall = function (data) {
                return doRequest('call', 'add', 'post', data);
            };
            apiFactory.postUpdateCall = function (data) {
                return doRequest('call', 'update', 'post', data);
            };
            apiFactory.postDeleteCall = function (id) {
                return doRequest('call', 'delete', 'post', {id: id});
            };
            apiFactory.postRemoveCallbackTime = function (id) {
                return doRequest('call', 'remove-callback-time', 'post', {call: {id: id}});
            };
            apiFactory.postSearchCalls = function (data) {
                return doRequest('call', 'search-calls', 'post', data);
            };
            apiFactory.postUpdateCallbackTime = function(data) {
                return doRequest('call', 'update-callback-time', 'post', {call_data:data});
            };
            apiFactory.getUnassignedCalls = function() {
                return doRequest('call', 'unassigned-calls', 'get', {});
            };
            apiFactory.getArchivedCalls = function(data) {
                return doRequest('call', 'get-archived-calls', 'post', data);
            };
            apiFactory.archiveCallsBetween = function(start_date, end_date) {
                return doRequest('call', 'archive-calls-between', 'post', {
                    start_date: start_date,
                    end_date: end_date
                });
            };
            apiFactory.postCompletedVolunteerCalls = function(data) {
                return doRequest('call', 'completed-volunteer-calls', 'post', data);
            };
            apiFactory.postAlertVolunteer = function(volunteer_id, call_id, alert) {
                return doRequest('call', 'alert-volunteer', 'post', {volunteer_id: volunteer_id, call_id: call_id, alert: alert});
            };

            // Events
            apiFactory.getAllEvents = function (data) {
                return doRequest('event', 'all-events', 'get', data);
            };
            apiFactory.getAllEventsUpcoming = function () {
                return doRequest('event', 'all-events-upcoming', 'get', {});
            };
            apiFactory.getEventById = function (id) {
                return doRequest('event', 'event-by-id', 'get', {id: id});
            };
            apiFactory.getAllVolunteerRegisteredEvents = function (volunteer_id) {
                return doRequest('event', 'all-volunteer-registered-events', 'get', {volunteer_id: volunteer_id});
            };
            apiFactory.postAddEvent = function (data) {
                const payload = {
                    event: {
                        name: data.name,
                        date: data.date,
                        description: data.description,
                        location_name: data.location_name,
                        location_address: data.location_address,
                        county_id: data.county_id, // Ensure county_id is included
                        event_blocks: data.event_blocks
                    }
                };
                return doRequest('event', 'add', 'post', payload);
            };
            apiFactory.postEditEvent = function (data) {
                return doRequest('event', 'edit', 'post', data);
            };
            apiFactory.postDeleteEvent = function (id) {
                return doRequest('event', 'delete', 'post', {id: id});
            };

            // Event Blocks
            apiFactory.getDeleteEventBlock = function (id) {
                return doRequest('event-block', 'delete', 'get', {id: id});
            };
            apiFactory.getEventBlocksById = function (id) {
                return doRequest('event-block', 'event-block-by-id', 'get', {event_id: id});
            };
            apiFactory.postRegisterForEvent = function (id) {
                return doRequest('event-block', 'register-for-event', 'post', {id: id});
            };
            apiFactory.postUnregisterForEvent = function (id) {
                return doRequest('event-block', 'unregister-for-event', 'post', {id: id});
            };

            // Counties
            apiFactory.getAllCounties = function () {
                return doRequest('counties', 'all-counties', 'get', {});
            };

            // Limited Calls
            apiFactory.getAllCountiesLimited = function () {
                return doPublicRequest('all-counties', 'get', {});
            };
            apiFactory.postAddCallLimited = function (data) {
                return doPublicRequest('add-call', 'post', data);
            };
            apiFactory.getScheduleBySubjectsLimited = function(data) {
                return doPublicRequest('user-times', 'get', data);
            };

            // Password Reset
            apiFactory.postPasswordResetPermission = function (data) {
                return doRequest('password-reset', 'password-reset-permission', 'post', data);
            };
            apiFactory.postUpdateUserPassword = function (data) {
                return doRequest('password-reset', 'update-user-password', 'post', data);
            };
            apiFactory.postRequestNewPassword = function (data) {
                return doRequest('password-reset', 'request-new-password', 'post', data);
            };
            apiFactory.revokeAuthentication = function () {
                return doRequest('auth', 'revoke', 'get');
            };

            // Reports
            apiFactory.getSHIPExport = function (data) {
                return doRequest('ship-export', 'ship-export', 'get', data);
            };
            apiFactory.getOnTimeReport = function (data) {
                return doRequest('call', 'on-time-report', 'get', data);
            };

            // File Upload
            apiFactory.temporaryFileUpload = function (file, type) {
                return doRequestFile('file', 'temporary', file, type);
            };

            apiFactory.getFakeData = function () {
                return doRequest('tester', 'fake-data', 'get');
            };

            return apiFactory;

            function doPublicRequest(midpoint, method, data) {
                var url = rootpoint + '/' + midpoint;
                var cancel_q = $q.defer();
                var q = $q(function (resolve, reject) {
                    switch (method) {
                        case 'get':
                            return $http.get(url, {params: data, timeout: cancel_q.promise}).then(function (data, status, headers) {
                                onValid.call(this, data.data);
                                resolve.call(this, data.data.data);
                            }).catch(function (data) {
                                onError.call(this, data);
                                reject.call(this, data.data.error);
                            });
                        case 'post':
                            return $http.post(url, data, {timeout: cancel_q.promise}).then(function (data, status, headers) {
                                onValid.call(this, data.data);
                                resolve.call(this, data.data.data);
                            }).catch(function (data) {
                                onError.call(this, data);
                                reject.call(this, data.data.error);
                            });
                    }
                });

                q.cancel = function(reason) {
                    cancel_q.resolve(reason);
                };

                return q;
            }

            function doRequest(midpoint, endpoint, method, data) {
                var url = rootpoint + '/' + midpoint + '/' + endpoint;
                var cancel_q = $q.defer();
            
                var q = $q(function (resolve, reject) {
                    switch (method) {
                        case 'get':
                            return $http.get(url, { params: data, timeout: cancel_q.promise }).then(function (response) {
                                onValid.call(this, response.data);
                                resolve.call(this, response.data.data);
                            }).catch(function (response) {
                                console.error("GET request error:", response);
                                console.error("Error response data:", response.data);
                                onError.call(this, response);
                                reject.call(this, response.data.error);
                            });
                        case 'post':
                            return $http.post(url, data, { timeout: cancel_q.promise }).then(function (response) {
                                onValid.call(this, response.data);
                                resolve.call(this, response.data.data);
                            }).catch(function (response) {
                                console.error("POST request error:", response);
                                console.error("Error response data:", response.data);
                                onError.call(this, response);
                                reject.call(this, response.data ? response.data.error : response.statusText);
                            });
                    }
                });
            
                q.cancel = function(reason) {
                    cancel_q.resolve(reason);
                };
            
                return q;
            }
            
            function doRequestFile(midpoint, endpoint, file, type) {
                var url = rootpoint + '/' + midpoint + '/' + endpoint;

                var fd = new FormData();
                fd.append('file', file);
                if (type) {
                    fd.append('type', type);
                }

                return $q(function (resolve, reject) {
                    $http.post(url, fd, {
                        transformRequest: angular.identity,
                        headers: {'Content-Type': undefined}
                    }).then(function (data, status, headers) {
                        onValid.call(this, data.data);
                        resolve.call(this, data.data.data);
                    }).catch(function (data) {
                        onError.call(this, data);
                        reject.call(this, data.data.error);
                    });
                });
            }

            function onValid(data) {
                if (data.hasOwnProperty('immediate_action') && data.immediate_action) {
                    (data.immediate_action);
                }
            }

            function onError(data) {
                if (data.status == 401) {
                    $rootScope.$broadcast('authenticated', false);
                }
            }
        }
    ]);
})(window.skeletonApp);

