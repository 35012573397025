'use strict';


(function (app)
{

    app.factory('alerts', [
        '$uibModal',
        function ($uibModal)
        {
            var alerts = {};

            alerts.error = function(title, message, callback)
            {
                var modalInstance = $uibModal.open({
                    templateUrl: '/templates/alerts/error.html',
                    controller: 'AlertsCtrl',
                    resolve: {
                        message: function() { return message; },
                        title: function() { return title; }
                    }
                });

                modalInstance.result.then(callback, callback);
            };

            alerts.success = function (title, message, callback)
            {
                var modalInstance = $uibModal.open({
                    templateUrl: '/templates/alerts/success.html',
                    controller: 'AlertsCtrl',
                    resolve: {
                        message: function() { return message; },
                        title: function() { return title; }
                    }
                });

                modalInstance.result.then(callback, callback);
            };
            alerts.confirm = function (title, message, yes_callback, no_callback)
            {
                var modalInstance = $uibModal.open({
                    templateUrl: '/templates/alerts/confirm.html',
                    controller: 'AlertsCtrl',

                    resolve: {
                        message: function() { return message; },
                        title: function() { return title; }
                    }
                });

                modalInstance.result.then(yes_callback, no_callback);
            };
            alerts.timeSet = function (edit, volunteer_schedules, ok_callback, removeCallback, admin)
            {
                var modalInstance = $uibModal.open(
                    {
                        templateUrl:'/templates/alerts/timeSet.html',
                        controller: 'TimeSetCtrl',
                        size:'lg',
                        resolve:
                        {
                            edit:function() { return edit;},
                            volunteer_schedules:function() { return volunteer_schedules; },
                            removeCallback:function(){return removeCallback},
                            admin:function() { if(admin) return admin; return false;}
                        }
                    }
                );
                modalInstance.result.then(ok_callback, null);
            };
            
            alerts.eventSet = function(edit, event_data, send_callback, removeCallback)
            {
                var modalInstance = $uibModal.open(
                    {
                        templateUrl:'/templates/alerts/eventSet.html',
                        controller:'EventSetCtrl',
                        size:'lg',
                        resolve:
                        {
                            edit:function() { return edit; },
                            event_data: function() { return event_data; },
                            removeCallback:function() { return removeCallback; },
                            send_callback:function() { return send_callback;}
                        }

                    }
                );
                modalInstance.result.then(null,null);
            };
            alerts.eventRegister = function(event_data,user_id,refresh_event_page)
            {
                var modalInstance = $uibModal.open(
                    {
                        templateUrl:'/templates/alerts/eventRegister.html',
                        controller:'EventRegisterCtrl',
                        size:'xl',
                        resolve:
                        {
                            event_data:function() { return event_data; },
                            user_id: function() { return user_id; },
                            refresh_event_page: function() { return refresh_event_page; }
                        }
                    }
                );
                modalInstance.result.then(null,null);

            };
            alerts.viewCall = function(call_data, resolve_callback, un_volunteer_call, update_callback)
            {
                var modalInstance = $uibModal.open(
                    {
                        templateUrl:'/templates/alerts/callInfo.html',
                        controller:'CallInfoCtrl',
                        size:'xl',
                        resolve:
                        {
                            call_data: function() { return call_data; },
                            resolve_callback:function() {return resolve_callback;},
                            un_volunteer_call:function() {return un_volunteer_call;},
                            update_callback:function() {return update_callback; },
                        }
                    }
                );
                modalInstance.result.then(null,null);
            };
            alerts.callRegister = function(call_data, volunteer_id, register_callback, close_callback) {
                var modalInstance = $uibModal.open({
                    templateUrl: '/templates/alerts/unassigned-calls-alert.html',
                    controller: 'UnassignedCallsAlertCtrl',
                    size: 'xl',
                    resolve: {
                        call_data: function() { return call_data; },
                        volunteer_id: function() { return volunteer_id; },
                        register_callback: function() { return register_callback; }
                    }
                });
            
                modalInstance.result.then(function(updatedCallData) {
                    if (register_callback) {
                        register_callback(updatedCallData);
                    }
                    if (close_callback) {
                        close_callback();
                    }
                }, function() {
                    if (close_callback) {
                        close_callback();
                    }
                });
            };
            
            
            
                            
            alerts.callArchiver = function(archive_call_callback)
            {
                var modalInstance = $uibModal.open(
                    {
                        templateUrl:'/templates/alerts/call-archiver.html',
                        controller:'CallArchiverCtrl',
                        size:'lg',
                        resolve:
                            {
                                archive_call_callback: function() { return archive_call_callback; }
                            }
                    }
                );
                modalInstance.result.then(null, null);
            };
          
            return alerts;
        }
    ]);


})(window.skeletonApp);