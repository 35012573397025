'use strict';
(function (app)
{
    app.controller('CallImportCtrl', [
        '$scope','api','Upload','$timeout', 'SweetAlert',
        function ($scope, api, Upload, $timeout, SweetAlert)
        {
        $scope.loading = false;
            $scope.uploadPic = function(file) {
                if($scope.myForm.$valid) {
                    $scope.loading = true;
                    file.upload = Upload.upload({
                        url: '/api/call/import-calls',
                        data: {file: file}
                    });
                    file.upload.then(function (response) {
                        $timeout(function () {
                            file.result = response.data;
                            $scope.loading = false;
                            SweetAlert.swal('Success', 'The call spreadsheet was imported!', 'success');
                        });
                    }, function (response) {
                        SweetAlert.swal('Error', 'Parsing of file failed. Contact administrator.', 'error');
                        if (response.status > 0) {
                            console.log(response);
                        }
                    }, function (evt) {
                        $scope.loading = true;
                        // Math.min is to fix IE which reports 200% sometimes
                        file.progress = Math.min(100, parseInt(100.0 * evt.loaded / evt.total));
                    });
                    $scope.loading = false;
                }
            }
        }
    ]);
})(window.skeletonApp);
