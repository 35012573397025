/**
 * Created by mwillard on 5/18/16.
 */
(function(app)
{
    app.controller('ChangePasswordAlertCtrl', [
        '$scope', 'api', 'SweetAlert',
        function($scope, api, SweetAlert)
        {
            $scope.submit = function()
            {
                if($scope.user_password != $scope.user_password_confirm)
                {
                    SweetAlert.swal('Error', 'Your passwords do not match!', 'error');
                    return;
                }
                if(!$scope.user_password.length || !$scope.user_password_confirm.length)
                {
                    SweetAlert.swal('Error', 'Please fill in all fields!', 'error');
                    return;
                }
                
                api
                    .postChangeUserPassword({password:$scope.user_password})
                    .then(function(response)
                    {
                        SweetAlert.swal('Success', 'Your password has been updated!', 'success');
                    })
                    .catch(function(response)
                    {
                        SweetAlert.swal('Error', response, 'error');
                    });

            }
        }
    ])
})(window.skeletonApp);