'use strict';

(function (app) {
    app.controller('EventCtrlAdmin', [
        '$scope', '$routeParams', 'api', 'alerts', 'SweetAlert', 'uiCalendarConfig',
        function ($scope, $routeParams, api, alerts, SweetAlert, uiCalendarConfig) {

            $scope.current_events = [];
            $scope.event_data = {
                event_blocks: []
            };

            $scope.counties = [];
            $scope.selectedCountyId = ''; // Initialize with empty value for all counties

            // Define colors for each county based on name
            $scope.countyColors = {
                'LIVINGSTON': '#1E90FF',
                'MACOMB': '#32CD32',
                'MONROE': '#FFD700',
                'OAKLAND': '#FF4500',
                'ST. CLAIR': '#8A2BE2',
                'WAYNE': '#FF6347',
                'Other': '#808080',
                'WASHTENAW': '#FF69B4'
            };

            // Load counties
            api.getAllCounties().then(function (result) {
                $scope.counties = result.counties;
                console.log('Counties loaded:', $scope.counties);
            }).catch(function (error) {
                console.error('Error loading counties:', error);
            });

            $scope.getCountyNameById = function(countyId) {
                const county = $scope.counties.find(county => county.id == countyId);
                return county ? county.name : 'Unknown';
            };

            $scope.getCountyColor = function(countyName) {
                const color = $scope.countyColors[countyName] || '#039BE5'; // Default color if not found
                console.log('Color for county', countyName, ':', color);
                return color;
            };

            $scope.refreshEvents = function () {
                if (uiCalendarConfig.calendars.myCalendar) {
                    uiCalendarConfig.calendars.myCalendar.fullCalendar('refetchEvents');
                } else {
                    console.warn('Calendar not initialized yet.');
                }
            };
            

            $scope.getAllEvents = function (start, end, timezone, callback) {
                $scope.current_events.length = 0;
            
                const params = {
                    start_time: start.format(),
                    end_time: end.format()
                };
            
                if ($scope.selectedCountyId) {
                    params.county_id = $scope.selectedCountyId;
                }
            
                console.log('Fetching events with params:', params);
            
                api.getAllEvents(params)
                    .then(function (result) {
                        console.log('Events fetched:', result.events);
                        result.events.forEach(event => {
                            const countyName = $scope.getCountyNameById(event.county_id);
                            console.log('County name for event', event.id, ':', countyName);
                            let color = $scope.getCountyColor(countyName); // Get color based on county name
                            console.log('Initial color for event', event.id, ':', color);
            
                            event.date = new Date(moment(event.date).format('M/D/Y, h:mm A'));
            
                            // Update event block times
                            event.event_blocks.forEach(event_block => {
                                event_block.start_time = new Date(moment(event_block.start_time).format('h:mm A'));
                                event_block.end_time = new Date(moment(event_block.end_time).format('h:mm A'));
                            });
            
                            // Check if event is registered or full
                            event.event_blocks.forEach(event_block => {
                                event_block.event_volunteers.forEach(volunteer => {
                                    if (volunteer.volunteer_id == $scope.current_user.volunteer.id) {
                                        event.registered = true;
                                    }
                                });
                                if (event_block.volunteers_needed <= event_block.event_volunteers.length) {
                                    event.full = true;
                                } else {
                                    event.full = false;
                                }
                            });

                            // Adjust color based on registration and fullness
                            if (event.registered) color = '#4CAF50';
                            if (event.full && !event.registered) color = '#FF5722';
                            console.log('Final color for event', event.id, ':', color);

                            $scope.current_events.push({
                                id: event.id,
                                title: event.name,
                                start: event.date,
                                color: color, // Set color
                                event_blocks: event.event_blocks
                            });
                        });
                        callback($scope.current_events);
                    })
                    .catch(function (result) {
                        console.error('Error fetching events:', result);
                    });
            };

            $scope.addEvent = function (time) {
                $scope.event_data = {};
                if (time) {
                    $scope.event_data.date = new Date(time.set('hour', 8).format('M/D/Y, h:mm A'));
                } else if (!$scope.event_data.date) {
                    $scope.event_data.date = new Date(moment().set('hour', 8).set('minute', 0).format('M/D/Y, h:mm A'));
                }
                alerts.eventSet(false, $scope.event_data,
                    function (result, $uiModal) {
                        $uiModal.close();
                        var event_data = result;

                        event_data.event_blocks.forEach(event_block => {
                            event_block.start_time = new Date(moment(event_block.start_time).format());
                            event_block.end_time = new Date(moment(event_block.end_time).format());
                        });

                        api.postAddEvent({
                            event: {
                                date: moment(event_data.date).format(),
                                name: event_data.name,
                                description: event_data.description,
                                location_name: event_data.location_name,
                                location_address: event_data.location_address,
                                county_id: event_data.county_id,
                                event_blocks: event_data.event_blocks
                            }
                        }).then(function (response) {
                            // Success, delete current info
                            $scope.event_data = {};
                            SweetAlert.swal('Success', 'Your event has been saved!', 'success');
                            $scope.refreshEvents();
                        }).catch(function (response) {
                            SweetAlert.swal('Error', 'There was an issue saving the event! Please try again!', 'error');
                            $scope.addEvent();
                        });
                    },
                    function (result) {
                        // Remove Callback
                    });
            };

            $scope.removeEvent = function (data, uiModal) {
                SweetAlert.swal({
                    title: "Are you sure?",
                    text: "Remove this event?",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Yes, remove it",
                    closeOnConfirm: false,
                    closeOnCancel: true,
                }, function (confirm) {
                    if (confirm)
                        api.postDeleteEvent({ id: data.id })
                            .then(function (response) {
                                SweetAlert.swal('Success', 'Successfully removed!', 'success');
                                uiModal.dismiss();
                                $scope.refreshEvents();
                            })
                            .catch(function (response) {
                                SweetAlert.swal('Error', 'There was an error!\n' + response, 'error');
                            });
                });
            };

            $scope.editEvent = function (event) {
                if (!event.id) return;

                api.getEventById(event.id)
                    .then(function (response) {
                        response.event.date = new Date(moment(response.event.date).format('M/D/YYYY, h:mm A'));

                        alerts.eventSet(true, response.event,
                            function (result, $uiModal) {
                                $scope.event_data = result;
                                var event_data = result;

                                event_data.event_blocks.forEach(event_block => {
                                    event_block.start_time = new Date(moment(event_block.start_time).format());
                                    event_block.end_time = new Date(moment(event_block.end_time).format());
                                });

                                api.postEditEvent({
                                    event: {
                                        id: event_data.id,
                                        date: moment(event_data.date).format(),
                                        name: event_data.name,
                                        description: event_data.description,
                                        location_name: event_data.location_name,
                                        location_address: event_data.location_address,
                                        county_id: event_data.county_id,
                                        event_blocks: event_data.event_blocks
                                    }
                                }).then(function (response) {
                                    SweetAlert.swal('Success', 'Event has been updated!', 'success');
                                    $scope.refreshEvents();
                                }).catch(function (response) {
                                    SweetAlert.swal('Error', response.error, 'error');
                                });
                            }, $scope.removeEvent);
                    })
                    .catch(function (response) {
                        SweetAlert.swal('Error', response.error, 'error');
                    });
            };

            $scope.filterByCounty = function () {
                console.log('Filtering by county:', $scope.selectedCountyId);
                
                // Add a timeout to ensure the calendar is initialized
                setTimeout(function() {
                    $scope.refreshEvents();
                }, 200); // Adjust the delay as necessary (100ms should generally be sufficient)
            };
            

            // Trigger filterByCounty on page load to ensure events are properly colored
            $scope.filterByCounty();

            if ($routeParams.event_id) {
                $scope.editEvent({ id: $routeParams.event_id });
            }

            $scope.loading_events = true;
            // Calendar
            $scope.uiConfig = {
                calendar: {
                    editable: false,
                    selectable: false,
                    eventLimit: true,
                    allDaySlot: false,
                    header: {},
                    dayClick: $scope.addEvent,
                    eventClick: $scope.editEvent,
                    loading: function (loading) { $scope.loading_events = loading; },
                    events: $scope.getAllEvents // Make sure this is correct
                }
            };
        }
    ]);
})(window.skeletonApp);
