'use strict';
//TODO: add max 4 login time, send error message
(function (app)
{
    app.controller('LoginCtrl',[
        '$scope', '$location', 'api', 'alerts', 'SweetAlert',
        function ($scope, $location, api, alerts, SweetAlert)
        {
            $scope.login = true;
            $scope.pending_check = true;
            $scope.authenticating = false;
            $scope.login_attempts = 0;
            $scope.max_login_attempts = 4;
            api.isAuthenticated()
                .then(function (result)
                {
                    if (result.authenticated)
                    {
                        $scope.$emit('authenticated', true);
                    }
                    else
                    {
                        $scope.pending_check = false;
                    }
                })
                .catch(function ()
                {
                    //TODO Something Here
                });
            $scope.login = function() {
                $scope.authenticating = true;
                if($scope.username && $scope.password) {
                    api.sendAuthentication($scope.username, $scope.password)
                        .then(function (result) {
                            $scope.authenticating = false;
                            if (result.authenticated) {
                                $scope.$emit('authenticated', true);
                            }
                            else {
                                $scope.password = '';
                                if(result.error_msg)
                                {
                                    SweetAlert.swal('Failed to Login', result.error_msg,'error');
                                }
                                else {
                                    if($scope.login_attempts < $scope.max_login_attempts) {
                                        $scope.login_attempts++;
                                        SweetAlert.swal('Failed to Login', 'Your login credentials are invalid. Please try again. \n' +
                                            'Tries left: ' + ($scope.max_login_attempts - ($scope.login_attempts - 1)), 'error');
                                    }
                                    else
                                    {
                                        $scope.authenticating = true;
                                        api
                                            .postRequestNewPassword(
                                                {
                                                    email:$scope.username
                                                }
                                            )
                                            .then(function(response)
                                            {
                                                SweetAlert.swal('Error', 'Your login attempts have exceeded the max amount! ' +
                                                    'Your password needs to be reset. You have been sent an email with directions.', 'error');
                                            })
                                            .catch(function(response)
                                            {
                                                $scope.authenticating = false;
                                                SweetAlert.swal('Error', response, 'error');
                                            });
                                    }
                                }
                            }
                        })
                        .catch(function (result) {
                            $scope.authenticating = false;
                        });
                }
                else
                    SweetAlert.swal("Empty Fields","Please fill in all fields.",'error');
            }
            $scope.forgotPassword = function()
            {
                api
                    .postRequestNewPassword(
                        {
                            email:$scope.email
                        }
                    )
                    .then(function(response)
                    {
                        SweetAlert.swal('Success', 'You have been sent an email!', 'success');
                    })
                    .catch(function(response)
                    {
                       SweetAlert.swal('Error', response, 'error');
                    });
            }
        }
    ]);
})(window.skeletonApp);