'use strict';
(function (app) {
    app.controller('AppCtrl', [
        '$scope', '$location', '$route', '$uibModal', 'api', 'SweetAlert', '$window',
        function ($scope, $location, $route, $uibModal, api, SweetAlert, $window) {
            $scope._user_types = {
                ADMIN: 'admin',
                SITE_COORD: 'site_coord',
                CALL_CENTER: 'call_center',
                VOLUNTEER: 'volunteer'
            };
            $scope.non_auth_sites = ['/web-call-intake', '/password-reset', '/thanks'];
            $scope.non_auth_sites_reg = new RegExp($scope.non_auth_sites.join('|'));
            $scope.password_reset_reg = new RegExp('/password-reset');
            $scope.password_reset = false;
            $scope.is_authenticated = false;
            $scope.original_path = $location.path();
            $scope.openGuide = function () {
                window.open('/pdf/guide.pdf');
            };
            $scope.menus = [
                {
                    name: 'Home',
                    href: '/home',
                    icon: 'home',
                    active: false,
                    canView: ["admin", "site_coord", "volunteer"]
                },
                {
                    name: 'Change Password',
                    href: '/password-change',
                    icon: 'lock',
                    active: false,
                    canView: ["admin", "site_coord", "volunteer", "call_center"]
                },
                {
                    name: 'Logout',
                    href: '/logout',
                    icon: 'exit_to_app',
                    active: false,
                    canView: ["admin", "site_coord", "volunteer", "call_center"]
                }
            ];
            $scope.sideMenus = [
                {
                    name: 'Home',
                    href: '/home',
                    icon: 'home',
                    active: false,
                    canView: ["admin", "site_coord", "volunteer"]
                },
                {
                    name: 'Call Intake',
                    href: '/call-intake',
                    icon: 'perm_phone_msg',
                    active: false,
                    canView: ["admin", "site_coord", "call_center"]
                },
                {
                    name: 'Calls',
                    href: '/call-queue/pending',
                    icon: 'settings_phone',
                    active: false,
                    canView: ["admin", "site_coord"],
                    items: [
                        {
                            name: 'Pending Calls',
                            href: '/call-queue/pending',
                            icon: 'settings_phone',
                            active: false,
                            canView: ["admin", "site_coord"]
                        }, {
                            name: 'Completed Calls',
                            href: '/call-queue/complete',
                            icon: 'settings_phone',
                            active: false,
                            canView: ["admin", "site_coord"]
                        }, {
                            name: 'Unassigned Calls',
                            href: '/call-queue/unassigned',
                            icon: 'settings_phone',
                            active: false,
                            canView: ["admin", "site_coord"]
                        }
                    ]
                },
                {
                    name: 'Call Import',
                    href: '/call-import',
                    icon: 'add',
                    active: false,
                    canView: ["admin"]
                },
                {
                    name: 'Archived Calls',
                    href: '/archived-calls',
                    icon: 'archive',
                    active: false,
                    canView: ["admin"]
                },
                {
                    name: 'Event Calendar',
                    href: '/event-calendar',
                    icon: 'event',
                    active: false,
                    canView: ["admin", "site_coord", "volunteer"]
                },
                {
                    name: 'My Calls',
                    href: '/calls',
                    icon: 'call',
                    active: false,
                    canView: ["volunteer"],
                    items: [
                        {
                            name: 'Pending Calls',
                            href: '/calls/my-calls/pending',
                            icon: 'phone_in_talk',
                            active: false
                        },
                        {
                            name: 'Completed Calls',
                            href: '/calls/old',
                            icon: 'call_end',
                            active: false
                        },
                        {
                            name: 'Unassigned Calls',
                            href: '/calls/unassigned',
                            icon: 'settings_phone',
                            active: false
                        }
                    ]
                },
                {
                    name: 'Users',
                    href: '/users',
                    icon: 'person_add',
                    active: false,
                    items: [
                        {
                            name: 'View Users',
                            href: 'users/view-users',
                            icon: 'person_add',
                            active: false
                        },
                        {
                            name: 'Add User',
                            href: 'users/add-user',
                            icon: 'person_add',
                            active: false
                        },
                        {
                            name: 'Adjust Schedule',
                            href: 'users/adjust-time',
                            active: false
                        }
                    ],
                    canView: ['admin', 'site_coord']
                }
            ];

            // Stop impersonating user
            $scope.stopImpersonating = function () {
                api.stopImpersonating().then(function (response) {
                    if (response && response.success) {
                        SweetAlert.swal('Success', response.message || 'You have stopped impersonating.', 'success');
                        $scope.isImpersonating = false;
                        location.reload();
                    } else {
                        SweetAlert.swal('Error', response.message || 'Unable to stop impersonating user!', 'error');
                    }
                }).catch(function (error) {
                    SweetAlert.swal('Error', 'Unable to stop impersonating user!', 'error');
                    console.error('Stop impersonating error:', error);
                });
            };

            // Check impersonation state
            function checkImpersonationState() {
                api.getImpersonationState().then(function (response) {
                    if (response && typeof response.isImpersonating !== 'undefined') {
                        $scope.isImpersonating = response.isImpersonating;
                    } else {
                        console.error('Unexpected response structure:', response);
                    }
                }).catch(function (error) {
                    SweetAlert.swal('Error', 'Unable to get impersonation state!', 'error');
                });
            }

            $scope.$on('$locationChangeStart', function (event, location) {
                var password_reset_reg = new RegExp('/password-reset');
                if (password_reset_reg.test(location)) {
                    $scope.password_reset = true;
                    return;
                }
                for (var i in $scope.sideMenus) {
                    var item = $scope.sideMenus[i];
                    var reg = new RegExp(item.href);
                    //Can we be here?
                    if (reg.test(location)) {
                        if (item.canView.indexOf($scope.current_user.user_type.title) < 0) {
                            if ($scope.current_user.user_type.title != $scope._user_types.CALL_CENTER) {
                                SweetAlert.swal('Error', 'You lack the rights to view this page!', 'error');
                                $location.path('/home');
                            }
                            else
                                $location.path('/call-intake');
                        }
                    }
                }
            });

            $scope.$on('$locationChangeSuccess', function (event, location) {
                for (var i in $scope.sideMenus) {
                    var item = $scope.sideMenus[i];
                    var reg = new RegExp(item.href);
                    if (reg.test(location)) {
                        item.active = true;
                    }
                    else {
                        item.active = false;
                    }
                    if (item.hasOwnProperty('items')) {
                        for (var ii in item.items) {
                            var item2 = item.items[ii];
                            var reg2 = new RegExp(item2.href);
                            if (reg2.test(location)) {
                                item2.active = true;
                            }
                            else {
                                item2.active = false;
                            }
                        }
                    }
                }
            });

            $scope.$on('authenticated', function (ev, authenticated) {
                $scope.is_authenticated = authenticated;
                $scope.password_reset = false;
                if (authenticated) {
                    // Get current user
                    api.getCurrentUser().then(function (response) {
                        $scope.current_user = response.user;
                        console.log($scope.current_user);
                        if ($scope.original_path == '/login') {
                            $scope.original_path = '/home';
                        }
                        if ($scope.password_reset_reg.test($scope.original_path))
                            $scope.original_path = '/home';
                        $location.path($scope.original_path).replace();

                        // Check impersonation state only after authentication
                        checkImpersonationState();

                    }).catch(function () {
                        SweetAlert.swal('Error', 'There was an error logging you in! Please try again!', 'error');
                        $location.path('/logout');
                    });
                } else {
                    $location.path('/login');
                    $scope.original_path = '/home';
                    $scope.current_user = null;
                }
                $route.reload();
            });

            $scope.layout_check = function () {
                return $scope.non_auth_sites_reg.test($location.path());
            };

            if (!$scope.non_auth_sites_reg.test($location.path())) {
                $location.path('/login').replace();
            }

            $scope.HasRights = function (permissions) {
                if ($scope.current_user) {
                    return (permissions.indexOf($scope.current_user.user_type.title) >= 0);
                }
                return false;
            };

            $scope.pretty_title = function (title) {
                if (title == null)
                    return;
                switch (title.toLowerCase()) {
                    case 'admin':
                        return 'ADMIN';
                    case 'site_coord':
                        return 'SITE COORDINATOR'
                    case 'volunteer':
                        return 'VOLUNTEER';
                    case 'call_center':
                        return 'CALL CENTER';
                }
            };

            // Permission Helpers
            $scope.isAdmin = function () { return $scope.current_user.user_type.title == $scope._user_types.ADMIN; };
            $scope.isSiteCoordinator = function () { return $scope.current_user.user_type.title == $scope._user_types.SITE_COORD; };
            $scope.isVolunteer = function () { return $scope.current_user.user_type.title == $scope._user_types.VOLUNTEER; };
            $scope.isCallCenter = function () { return $scope.current_user.user_type.title == $scope._user_types.CALL_CENTER; };
        }
    ]);
})(window.skeletonApp);
