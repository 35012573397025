'use strict';
(function (app) {
    app.controller('EventSetCtrl', [
        '$scope', '$sce', 'api', 'SweetAlert', '$uibModalInstance', 'edit', 'event_data', 'removeCallback', 'send_callback',
        function ($scope, $sce, api, SweetAlert, $uibModalInstance, edit, event_data, removeCallback, send_callback) {
            $scope.loading = false;
            $scope.selected_volunteers = [];
            $scope.volunteer_users = [[]];
            $scope.counties = [];

            $scope.getCounties = function() {
                api.getAllCounties()
                    .then(function(response) {
                        $scope.counties = response.counties; 
                    })
                    .catch(function(error) {
                        SweetAlert.swal("Error", error, "error");
                    });
            };

            $scope.getCounties();

            $scope.removeVolunteer = function(call_id) {
                SweetAlert.swal({
                    title: "Are you sure?",
                    text: "Are you sure you want to remove this user?",
                    type: "warning",
                    showCancelButton: true
                }, function(confirm) {
                    if (confirm) {
                        api.postRemoveVolunteer(call_id)
                            .then(function() {
                                SweetAlert.swal("Success", "Volunteer removed!", "success");
                                $scope.intake_call.volunteer_user = null;
                            })
                            .catch(function(error) {
                                SweetAlert.swal("Error", error, "error");
                            });
                    }
                });
            };

            $scope.registerForThisEvent = function(event_block_id, volunteers) {
                api.postSaveEventVolunteers(event_block_id, volunteers)
                    .then(function(response) {
                        SweetAlert.swal('Success', response.okay, 'success');
                        $scope.getEventData();
                    })
                    .catch(function(response) {
                        SweetAlert.swal('Error', response, 'error');
                    });
            };

            $scope.submit = function() {
                if (!Date.parse($scope.my_event_data.date)) {
                    $scope.errorMsg = "The date of the event needs to be set!";
                    return;
                }

                if ($scope._checkEventBlocks()) {
                    // Prepare the data structure
                    const eventData = {
                        id: $scope.my_event_data.id || null,
                        name: $scope.my_event_data.name,
                        date: moment($scope.my_event_data.date).format('YYYY-MM-DD HH:mm:ss.SSS'),
                        description: $scope.my_event_data.description,
                        location_name: $scope.my_event_data.location_name,
                        location_address: $scope.my_event_data.location_address,
                        county_id: $scope.my_event_data.county_id,
                        event_blocks: $scope.my_event_data.event_blocks.map(block => ({
                            id: block.id || null,
                            start_time: moment(block.start_time).format('YYYY-MM-DD HH:mm:ss.SSS'),
                            end_time: moment(block.end_time).format('YYYY-MM-DD HH:mm:ss.SSS'),
                            volunteers_needed: block.volunteers_needed,
                            selected_volunteers: block.selected_volunteers
                        }))
                    };

                    console.log('Event Data:', eventData); // Log event data

                    if (edit) {
                        api.postEditEvent({ event: eventData })
                            .then(response => {
                                // Handle success
                                $uibModalInstance.close();

                                // Force page/window refresh after modal close
                                setTimeout(function () {
                                    window.location.reload();
                                }, 1000);
                            })
                            .catch(error => {
                                // Handle error
                                console.error('Error:', error);
                                $scope.errorMsg = 'Error: ' + error;
                            });
                    } else {
                        api.postAddEvent(eventData)
                            .then(response => {
                                // Handle success
                                $uibModalInstance.close();

                                // Force page/window refresh after modal close
                                setTimeout(function () {
                                    window.location.reload();
                                }, 1000);
                            })
                            .catch(error => {
                                // Handle error
                                console.error('Error:', error);
                                $scope.errorMsg = 'Error: ' + error;
                            });
                    }
                }
            };

            $scope._getEventBlocks = function() {
                api.getEventBlocksById($scope.my_event_data.id)
                    .then(function(response) {
                        $scope._adjustEventBlockTimes(response.event_blocks);
                        $scope._updateEventBlocks();
                    })
                    .catch(function(response) {
                        SweetAlert.swal('Error', response, 'error');
                    });
            };

            $scope.addEventBlock = function() {
                if (!$scope.my_event_data.event_blocks) {
                    $scope.my_event_data.event_blocks = [];
                }
            
                // Get the event date and time
                var eventDate = new Date($scope.my_event_data.date);
                var start_time = new Date(eventDate);
                var end_time = new Date(eventDate);
                end_time.setHours(end_time.getHours() + 1); // Set end_time to be one hour later
            
                $scope.my_event_data.event_blocks.push({
                    i: $scope.my_event_data.event_blocks.length,
                    start_time: start_time,
                    end_time: end_time,
                    volunteers_needed: 1,
                    selected_volunteers: []
                });
            };
            
            
            $scope._adjustEventBlockTimes = function(event_blocks) {
                if (!event_blocks) return;
                $scope.my_event_data.event_blocks = [];
                for (let i = 0; i < event_blocks.length; i++) {
                    let event_block = event_blocks[i];
                    event_block.start_time = new Date(event_block.start_time);
                    event_block.end_time = new Date(event_block.end_time);
                    $scope.my_event_data.event_blocks.push(event_block);
                    $scope.my_event_data.event_blocks[i].selected_volunteers = [];
                }
                $scope._updateEventBlocks();
            };

            $scope._updateEventBlocks = function() {
                for (let i = 0; i < $scope.my_event_data.event_blocks.length; i++) {
                    $scope.my_event_data.event_blocks[i].i = i;
                }
                $scope._getRegisteredVolunteers();
            };

            $scope._checkEventBlocks = function() {
                for (let i = 0; i < $scope.my_event_data.event_blocks.length; i++) {
                    let event_block = $scope.my_event_data.event_blocks[i];
                    if (new Date(event_block.start_time) >= new Date(event_block.end_time)) {
                        SweetAlert.swal('Error', 'An event block\'s start time must be before its end time!', 'error');
                        return false;
                    }
                    if (event_block.selected_volunteers.length > event_block.volunteers_needed) {
                        SweetAlert.swal('Error', 'Too many volunteers assigned to event block.', 'error');
                        return false;
                    }
                }
                return true;
            };

            $scope.removeEventBlock = function(id) {
                if (!$scope.my_event_data.event_blocks[id].id) {
                    $scope.my_event_data.event_blocks.splice(id, 1);
                    $scope._updateEventBlocks();
                } else {
                    SweetAlert.swal({
                        title: "Are you sure?",
                        text: "Are you sure you want to remove this time block?",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonText: "Yes, remove it",
                        confirmButtonColor: "#F62217",
                        closeOnConfirm: true,
                        closeOnCancel: true
                    }, function(confirm) {
                        if (confirm) {
                            api.getDeleteEventBlock($scope.my_event_data.event_blocks[id].id)
                                .then(function(response) {
                                    $scope.my_event_data.event_blocks.splice(id, 1);
                                    $scope._updateEventBlocks();
                                })
                                .catch(function(response) {
                                    SweetAlert.swal('Error', response.error, 'error');
                                });
                        }
                    });
                }
            };

            $scope._getRegisteredVolunteers = function() {
                $scope.registered_users = [];
                for (let i = 0; i < $scope.my_event_data.event_blocks.length; i++) {
                    let event_block = $scope.my_event_data.event_blocks[i];
                    let registered_event = { time: event_block.start_time + ' - ' + event_block.end_time, volunteers: [] };
                    if (!event_block.event_volunteers) break;
                    for (let ii = 0; ii < event_block.event_volunteers.length; ii++) {
                        registered_event.volunteers.push(event_block.event_volunteers[ii].volunteer.user.name);
                    }
                    if (registered_event.volunteers.length > 0) {
                        $scope.registered_users.push(registered_event);
                    }
                }
            };

            $scope.getMap = function() {
                return $sce.trustAsResourceUrl('https://google.com/maps/embed/v1/place?key=YOUR_GOOGLE_MAPS_API_KEY&q=' + $scope.my_event_data.location_address);
            };

            $scope.remove = function() {
                removeCallback({ id: $scope.my_event_data.id }, $uibModalInstance);
            };

            $scope.dismiss = function() {
                $uibModalInstance.dismiss();
            };

            $scope.edit = edit;
            $scope.removeCallback = removeCallback;
            let event_blocks = event_data.event_blocks;
            event_data.event_blocks = [];
            $scope.my_event_data = event_data;

            $scope._adjustEventBlockTimes(event_blocks);

            api.getAllVolunteerUsers()
                .then(function(res) {
                    for (let i = 0; i < 10; i++) {
                        $scope.volunteer_users[i] = angular.copy(res);
                        if (edit && event_blocks[i]) {
                            let eventVolunteers = event_blocks[i].event_volunteers;
                            if (eventVolunteers.length) {
                                let volunteer_ids = eventVolunteers.map(o => o.volunteer.user_id);
                                for (let u in $scope.volunteer_users[i]) {
                                    if (volunteer_ids.indexOf($scope.volunteer_users[i][u].id) !== -1) {
                                        $scope.volunteer_users[i][u].ticked = true;
                                    }
                                }
                            }
                        }
                    }
                })
                .catch(function(error) {
                    SweetAlert.swal("Error", error, "error");
                });
        }
    ]);
})(window.skeletonApp);
