(function(app) {
    app.controller('UnassignedCallsAlertCtrl', [
        '$scope', 'api', '$uibModalInstance', 'SweetAlert', 'call_data', 'volunteer_id', 'register_callback',
        function($scope, api, $uibModalInstance, SweetAlert, call_data, volunteer_id, register_callback) {
            // Fetch counties
            $scope.getCounties = function() {
                api.getAllCounties()
                    .then(function(result) {
                        $scope.counties = result.counties;
                        for (var i = 0, len = $scope.counties.length; i < len; i++) {
                            if ($scope.counties[i].name === "Declined") {
                                var item = $scope.counties[i];
                                $scope.counties.sort();
                                $scope.counties.splice(i, 1);
                                $scope.counties.splice(0, 0, item);
                            }
                        }
                    })
                    .catch(function() {
                        SweetAlert.swal('Error', 'There was an issue grabbing all counties! Please refresh the page!', 'error');
                    });
            };

            // Function to map caller_county_id to county name
            $scope.mapCountyName = function(county_id) {
                var county = $scope.counties.find(function(c) {
                    return c.id == county_id;
                });
                return county ? county.name : '';
            };

            // Initial fetch of counties
            $scope.getCounties();

            // Subject mapping function
            $scope._subjects = function() {
                var subjects = [];
                if ($scope.call_data.subjects.new_to_medicare == 1)
                    subjects.push({ title: 'New to Medicare' });
                if ($scope.call_data.subjects.original_medicare == 1)
                    subjects.push({ title: 'Original Medicare (A & B)' });
                if ($scope.call_data.subjects.prescription_drug_coverage == 1)
                    subjects.push({ title: 'Prescription Drug Coverage (Part D)' });
                if ($scope.call_data.subjects.medicare_advantage == 1)
                    subjects.push({ title: 'Medicare Advantage (Part C)' });
                if ($scope.call_data.subjects.medigap == 1)
                    subjects.push({ title: 'Medigap (Supplement)' });
                if ($scope.call_data.subjects.medicare_medicaid_dual == 1)
                    subjects.push({ title: 'Medicare-Medicaid Dual' });
                if ($scope.call_data.subjects.spenddown == 1)
                    subjects.push({ title: 'Spenddown' });
                if ($scope.call_data.subjects.medicare_savings_program == 1)
                    subjects.push({ title: 'Medicare Savings Program (MSP)' });
                if ($scope.call_data.subjects.extra_help_lis == 1)
                    subjects.push({ title: 'Extra Help/Low Income Subsidy (LIS)' });
                if ($scope.call_data.subjects.fraud_abuse_scams == 1)
                    subjects.push({ title: 'Fraud, Abuse, Scams (SMP)' });
                if ($scope.call_data.subjects.long_term_care == 1)
                    subjects.push({ title: 'Long Term Care (LTC)' });
                if ($scope.call_data.subjects.billing_issues == 1)
                    subjects.push({ title: 'Billing Issues' });
                if ($scope.call_data.subjects.appeals == 1)
                    subjects.push({ title: 'Appeals' });
                if ($scope.call_data.subjects.disability == 1)
                    subjects.push({ title: 'Disability' });
                if ($scope.call_data.subjects.other)
                    subjects.push({ title: 'Other: ' + $scope.call_data.subjects.other });
                return subjects;
            };

            $scope.formatCarbonDate = function(date) {
                return moment(date.date).format('h:mm A');
            };

            $scope.submit = function() {
                SweetAlert.swal({
                    title: "Are you sure?",
                    text: "Are you sure you want to register to this call?",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Okay",
                    closeOnConfirm: true,
                    closeOnCancel: true
                }, function(confirm) {
                    if (confirm) {
                        console.log("Adding volunteer with ID (user_id):", volunteer_id);
                        if (!volunteer_id) {
                            SweetAlert.swal("Error", "Volunteer ID is missing.", "error");
                            return;
                        }
                        // Adding the current user as the volunteer
                        api.postAddVolunteer($scope.call_data.id, volunteer_id)
                            .then(function(res) {
                                SweetAlert.swal("Success", "Volunteer added to call!", "success");
                                $scope.call_data.volunteer_user = res.volunteer;
                                $uibModalInstance.close($scope.call_data); // Close the modal with updated data
                            })
                            .catch(function(error) {
                                console.error("Error adding volunteer:", error);
                                SweetAlert.swal("Error", error.error || "An error occurred while adding the volunteer.", "error");
                            });
                    }
                });
            };

            $scope.dismiss = function() {
                $uibModalInstance.dismiss();
            };

            $scope.registerForCall = function() {
                console.log("Adding volunteer with ID (user_id):", volunteer_id);
                if (!volunteer_id) {
                    SweetAlert.swal("Error", "Volunteer ID is missing.", "error");
                    return;
                }
                api.postAddVolunteer($scope.call_data.id, volunteer_id)
                    .then(function(res) {
                        SweetAlert.swal("Success", "Volunteer added to call!", "success");
                        $uibModalInstance.close($scope.call_data); // Close the modal with updated data
                    })
                    .catch(function(error) {
                        console.error("Error adding volunteer:", error);
                        SweetAlert.swal("Error", error.error || "An error occurred while adding the volunteer.", "error");
                    });
            };

            $scope.call_data = call_data;

            if (!$scope.call_data.volunteer_schedule_id || $scope.call_data.volunteer_schedule_id == 0)
                $scope.call_data.volunteer_schedule_id = "0";

            $scope.subjects = $scope._subjects();

            // Map caller_county_id to county name after fetching counties
            $scope.$watch('counties', function(newCounties) {
                if (newCounties) {
                    $scope.call_data.county = {
                        name: $scope.mapCountyName($scope.call_data.caller_county_id)
                    };
                }
            });
        }
    ]);
})(window.skeletonApp);
