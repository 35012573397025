(function(app)
{
    app.controller('UserModCtrl',[
        '$scope','$rootScope','$routeParams','$location','api','SweetAlert','$route',
        function($scope,$rootScope, $routeParams, $location, api, SweetAlert, $route)
        {
                    // Initialize selected_counties_volunteer as an empty array
                    $scope.selected_counties_volunteer = [];
            $scope.getUser = function(user_id)
    
            {
                api
                    .getUserById(user_id)
                    .then(function(results)
                    {
                        $scope.user = results.user;
                        if(results.user.volunteer)
                        {
                            $scope.getSubjects(results.user.volunteer.subject);
                            $scope.volunteer = results.user.volunteer;
                        }
                        if(results.user.site_coordinator)
                            $scope.site_coordinator = results.user.site_coordinator;
                        api
                            .getAllCounties()
                            .then(function (results) {
                                $scope.counties = [];//results.counties;
                                //Convert counties to the isteven multiselect directive
                                for (var i = 0; i < results.counties.length; i++) {
                                    var county = results.counties[i];
                                    var new_county = {
                                        ticked: false,
                                        id: county.id,
                                        name: county.name
                                    };
                                    if ($scope.user.user_type_id == 2 && Array.isArray($scope.site_coordinator.counties)) {
                                        for (var x = 0; x < $scope.site_coordinator.counties.length; x++) {
                                            var site_coordinator_county = $scope.site_coordinator.counties[x];
                                            if (site_coordinator_county.county_id == new_county.id)
                                                new_county.ticked = true;
                                        }
                                    }
                                    
                                    if ($scope.user.user_type_id == 4) {
                                        for (var x = 0; x < $scope.volunteer.counties.length; x++) {
                                            var volunteer_county = $scope.volunteer.counties[x];
                                            if (volunteer_county.county_id == new_county.id)
                                                new_county.ticked = true;
                                        }
                                    }
                                    $scope.counties.push(new_county);
                                }
                            })
                            .catch(function (results) {
                                SweetAlert.swal('Error', results, 'error');
                            });
                    })
                    .catch(function(results)
                    {
                        SweetAlert.swal('Error', results, 'error');
                        $location.path('/add-user');
                    });
            };
            $scope.getAllSiteCoordinators = function()
            {
                api
                    .getSiteCoordinators()
                    .then(function(results)
                    {
                        $scope.site_coordinators = results.site_coordinators;
                    })
                    .catch(function(results)
                    {
                        SweetAlert.swal('Error',results,'error');
                    });
            };
            $scope.getSubjects = function($subjects)
            {
                angular.forEach($subjects, function(value, key)
                {
                    $scope.subjects[key] = value == 1 ? true : false;
                },true);
            };
            //returns true if at least one subject is true
            $scope.SubjectHasTruth = function()
            {
                var result = false;
                angular.forEach($scope.subjects, function(value, key)
                {
                    if(value) {
                        result =  true;
                    }
                });
                return result;
            };
            $scope.submit = function()
            {
                if($scope.user.user_type_id == 4)
                {
                    if(!$scope.SubjectHasTruth()) {
                        SweetAlert.swal('Error', 'At least ONE subject matter has to be selected!', 'error');
                        return;
                    }
                    if($scope.selected_counties_volunteer.length <= 0)
                    {
                        SweetAlert.swal('Error', 'At least ONE county has to be selected!', 'error');
                        return;
                    }
                    $scope.volunteer.counties = $scope.selected_counties_volunteer;
                }
                if($scope.user.user_type_id == 2) {
                    var county_codes = [];
                    angular.forEach($scope.selected_counties, function (value, key) {
                        this.push(value.id);
                    }, county_codes);
                    $scope.site_coordinator.counties = county_codes;
                }
                $scope.saving = true;
                if(!$scope.user.id)
                    api
                        .postAddUser({user:$scope.user, subjects:$scope.subjects, volunteer:$scope.volunteer, site_coordinator:$scope.site_coordinator})
                        .then(function(response)
                        {
                            SweetAlert.swal('Success','The user has been created!', 'success');
                            $scope.subjects = {};
                            $scope.volunteer = {};
                            $scope.site_coordinator = {};
                            $scope.getAllSiteCoordinators();
                            $scope.saving = false;
                            $route.reload();
                        })
                        .catch(function(response)
                        {
                            SweetAlert.swal('Error', response,'error');
                            $scope.saving = false;
                        });
                else
                    api
                        .postUpdateUser({user:$scope.user, subjects:$scope.subjects, volunteer:$scope.volunteer, site_coordinator:$scope.site_coordinator})
                        .then(function(response)
                        {
                            SweetAlert.swal('Success','The user has been updated!', 'success');
                            $scope.getUser(response.user_id);
                            $scope.saving = false;
                        })
                        .catch(function(response)
                        {
                            SweetAlert.swal('Error', response,'error');
                            $scope.saving = false;
                        });
            };
            $scope.getDisableButtonText = function(user)
            {
                return user.status == 0 ? 'Disable' : 'Enable';
            };
            $scope.disable = function(user_id,status)
            {
                var enabled = status == 0 ? false : true;
                SweetAlert.swal(
                    {
                        title:'Are you sure?',
                        type:'warning',
                        closeOnConfirm:true,
                        showCancelButton:true
                    }
                    ,function(confirm)
                    {
                        if(confirm)
                        {
                            api.postUserStatus({user:{id:user_id,status:enabled}})
                                .then(function(response)
                                {
                                    SweetAlert.swal('Success','User\'s status has been ' + (!enabled ? 'Enabled' : 'Disabled'),'success');
                                    $scope.getUser(user_id);
                                })
                                .catch(function(response) {
                                    SweetAlert.swal('Error', response, 'error');
                                });
                        }
                    }
                )
            };
            $scope.user = {};
            $scope.subjects = {};
            $scope.volunteer = {};
            $scope.user_types = [];
            $scope.site_coordinators = [];
            $scope.site_coordinator = {};
            $scope.counties = [];
            $scope.saving = false;
            $scope.selected_counties = [];
            $scope.selected_counties_volunteer = [];
            (function()
            {
                if($routeParams.user_id)
                {
                    $scope.getUser($routeParams.user_id);
                }
                else {
                    api
                        .getAllCounties()
                        .then(function (results) {
                            $scope.counties = [];//results.counties;
                            //Convert counties to the isteven multiselect directive
                            for (var i = 0; i < results.counties.length; i++) {
                                var county = results.counties[i];
                                var new_county = {
                                    ticked: false,
                                    id: county.id,
                                    name: county.name
                                };
                                if ($scope.user.user_type_id == 2) {
                                    for (var x = 0; x < $scope.site_coordinator.counties.length; x++) {
                                        var site_coordinator_county = $scope.site_coordinator.counties[x];
                                        if (site_coordinator_county.county_id == new_county.id)
                                            new_county.ticked = true;
                                    }
                                }
                                if ($scope.user.user_type_id == 4) {
                                    for (var x = 0; x < $scope.volunteer.counties.length; x++) {
                                        var volunteer_county = $scope.volunteer.counties[x];
                                        if (volunteer_county.county_id == new_county.id)
                                            new_county.ticked = true;
                                    }
                                }
                                $scope.counties.push(new_county);
                            }
                        })
                        .catch(function (results) {
                            SweetAlert.swal('Error', results, 'error');
                        });
                }
                    //User types
                    api
                        .getUserTypes()
                        .then(function (results) {
                            for (var i = 0; i < results.user_types.length; i++) {
                                var user_type = results.user_types[i];
                                if (user_type.title == 'site_coord')
                                    user_type.title = 'Site Coordinator';
                                else if (user_type.title == 'call_center')
                                    user_type.title = 'Call Center';
                                else {
                                    var new_title = '';
                                    for (var ii = 0; ii < user_type.title.length; ii++) {
                                        if (ii == 0)
                                            new_title += user_type.title[ii].toUpperCase();
                                        else
                                            new_title += user_type.title[ii];
                                    }
                                    user_type.title = new_title;
                                }
                                $scope.user_types.push(user_type);
                            }
                        })
                        .catch(function (results) {
                            SweetAlert.swal('Error', results, 'error');
                        });
                    $scope.getAllSiteCoordinators();
            })();
        }
    ]);
})(window.skeletonApp);
