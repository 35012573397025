/**
 * Created by mwillard on 5/2/16.
 */
(function(app)
{
    app.controller('PasswordResetController',
        ['$scope','$routeParams','$location','api','SweetAlert',
        function($scope, $routeParams, $location, api, SweetAlert)
        {
            (function()
            {
                api
                    .postPasswordResetPermission(
                        {
                            email:$routeParams.user_email,
                            hash:$routeParams.password_hash
                        }
                    )
                    .then(function(response)
                    {
                        
                    })
                    .catch(function(response)
                    {
                        SweetAlert.swal('Error', response, 'error');
                        $scope.password_reset = false;
                        $location.path('/login');
                    })
            })();
            $scope.submit = function()
            {
                $scope.error_msg = '';
                if($scope.user_password != $scope.user_password_confirm)
                {
                    $scope.error_msg = 'Passwords do not match.';
                    return;
                }
                api
                    .postUpdateUserPassword(
                        {
                            email:$routeParams.user_email,
                            password:$scope.user_password
                        }
                    )
                    .then(function(response)
                    {
                        SweetAlert.swal('Success', 'Your password has been updated!', 'success');
                        $scope.password_reset = false;
                        $location.path('/login');
                    })
                    .catch(function(response)
                    {
                       SweetAlert.swal('Error', response, 'Error');
                    });
            };
        }
    ]);
})(window.skeletonApp);