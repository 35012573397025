(function (app) {
    app.controller('CallQueueCtrl', [
        '$scope', 'alerts', 'api', 'SweetAlert', '$routeParams', '$location',
        function ($scope, alerts, api, SweetAlert, $routeParams, $location) {
            $scope.isMyCalls = $location.path().indexOf('my-calls') >= 0;
            $scope.all_calls = [];
            $scope.displayed = [];
            $scope.subjects = {};

            $scope.getCompletedDate = function(date) {
                if (date === '0000-00-00 00:00:00') return 'Not Completed';
                return $scope.formatDate(date);
            };

            $scope.formatDate = function(date) {
                return moment(date).format('MM/DD/YYYY');
            };

            $scope.getStatus = function(status) {
                const statusMap = {
                    1: 'New',
                    2: 'In Progress',
                    4: 'Closed',
                };
                return statusMap[status] || '';
            };

            $scope.getContact = function(contact) {
                const contactMap = {
                    1: '(Phone Call)',
                    2: '(Sent Email)',
                    3: '(Left Voicemail)',
                    4: '(Unable to Contact)',
                    5: '(Other)'
                };
                return contactMap[contact] || '';
            };

            $scope.openCall = function (call) {
                var temp_call = {};
                angular.copy(call, temp_call);
                console.log('Opening call:', temp_call);
                
                // MARK AS VIEWED
                api.postUpdateCall({
                    call: {
                        info: {
                            id: temp_call.id,
                            has_alert: false
                        }
                    }
                })
                .then(function (results) {
                    console.log('Marked as viewed:', results);
                    call.has_alert = false;
                })
                .catch(function (results) {
                    SweetAlert.swal('Error', results, 'error');
                });
            
                alerts.viewCall(temp_call,
                    // Resolve the call
                    function (call_data, ui_model) {
                        api.postUpdateCall({
                            call: {
                                info: {
                                    id: call_data.id,
                                    status: 'r',
                                    has_alert: false
                                }
                            }
                        })
                        .then(function (results) {
                            console.log('Call resolved:', results);
                            $scope.getCalls($scope.tablestate);
                            SweetAlert.swal('Success', 'The call has been marked as resolved!', 'success');
                            ui_model.dismiss();
                        })
                        .catch(function (results) {
                            SweetAlert.swal('Error', results, 'error');
                        });
                    },
                    // Unvolunteer from call
                    function (id, ui_model) {
                        api.postRemoveVolunteer(id).then(function () {
                            console.log('Volunteer removed:', id);
                            $scope.getCalls($scope.tablestate);
                            ui_model.close();
                        });
                    },
                    // Update the call
                    function (call_data, ui_model) {
                        console.log('Updating call data:', call_data);
                        var completed_date = moment().format();
                        if (['1', '2', '3'].indexOf(call_data.status) >= 0) {
                            completed_date = '0000-00-00 00:00:00';
                        }
            
                        api.postUpdateCall({
                            call: {
                                info: {
                                    id: call_data.id,
                                    notes: call_data.notes,
                                    issue_description: call_data.issue_description,
                                    time_spent: call_data.time_spent,
                                    completed_date: completed_date,
                                    status: call_data.status,
                                    has_alert: false,
                                    age_group: call_data.age_group,
                                    caller_name: call_data.caller_name,
                                    caller_phone: call_data.caller_phone,
                                    address: call_data.address,
                                    caller_city: call_data.caller_city,
                                    zip: call_data.zip,
                                    caller_county_id: call_data.caller_county_id,
                                    site_coordinator_id: call_data.site_coordinator_id,
                                    contact: call_data.contact // Include contact here
                                }
                            }
                        })
                        .then(function (results) {
                            console.log('Call updated:', results);
                            $scope.getCalls($scope.tablestate);
                            ui_model.close();
                            SweetAlert.swal('Success', 'The call has been updated!', 'success');
                        })
                        .catch(function (results) {
                            SweetAlert.swal('Error', results.error, 'error');
                        });
                    }
                );
            };
            
            $scope.fetchSubjects = function(callId) {
                api.getSubjects(callId).then(function(response) {
                    console.log('Subjects fetched', response.data);
                    $scope.subjects = response.data;
                }).catch(function(error) {
                    console.log('Error fetching subjects', error);
                    $scope.subjects = {}; // Ensure it's defined to avoid undefined errors
                });
            };

            $scope.getAllUserCalls = function(tablestate) {
                tablestate.isMyCalls = $scope.isMyCalls;
                api.postSearchCalls(tablestate)
                .then(function(results) {
                    $scope.isLoading = false;
                    $scope.displayed = [].concat(results.calls);
                    tablestate.pagination.numberOfPages = Math.ceil(results.total / tablestate.pagination.number);
                })
                .catch(function(results) {
                    SweetAlert.swal('Error', results, 'error');
                });
            };

            $scope.viewAllCalls = function() {
                $scope.isLoading = true;
                var tablestate = {
                    pagination: {
                        start: 0,
                        number: 100 // or whatever number of items you want to fetch
                    },
                    sort: {
                        predicate: 'call_date_received',
                        reverse: false
                    },
                    search: {}
                };
                api.postSearchCalls(tablestate)  // Pass tablestate with pagination
                .then(function(results) {
                    $scope.isLoading = false;
                    $scope.displayed = [].concat(results.calls);
                })
                .catch(function(results) {
                    $scope.isLoading = false;
                    SweetAlert.swal('Error', results, 'error');
                });
            };
        }
    ]);
})(window.skeletonApp);
