'use strict';
(function (app)
{
    app.controller('CallIntakeLimitedCtrl', [
        '$scope','api','SweetAlert','$routeParams','$location',
        function ($scope, api,SweetAlert,$routeParams, $location)
        {
            // Get all the counties
            (function()
            {
                api
                    .getAllCountiesLimited()
                    .then(function(result)
                    {
                        $scope.counties = result.counties;
                    })
                    .catch(function(result)
                    {
                        SweetAlert.swal('error', 'There was an issue grabbing all counties! Please refresh the page!','error');
                    });
            })();
            $scope.intake_call =
            {
                how_ship:'9',
                continuing_contact:'1',
                age_group:'9',
                gender:'9',
                primary_language:'9',
                income:'9',
                assets:'9',
                apply_receive_ssd_md:'9',
                dual_eligible:'9',
                action:'A',
                contact: '1' // Add the new contact property here
            };
            $scope.new_intake_call = $scope.intake_call;
            $scope.subjects = {};
            $scope.available_schedules = [];
            $scope.loading_volunteers = false;
            $scope.volunteer_schedule_id = null;
            $scope.getMatchingSubjects = function(subjects)
            {
                // Don't send a call if we don't have at least 1 selected subject
                if(!$scope.SubjectHasTruth()) return;
                $scope.loading_volunteers = true;
                $scope.available_schedules = [];
                api
                    .getScheduleBySubjectsLimited(
                        {
                            subjects:subjects
                        }
                    )
                    .then(function(data)
                    {
                        angular.forEach(data.volunteer_schedules, function (schedule)
                        {
                            var label =   moment(schedule.start_time).format('MMM Do, h:mm a') +
                                ' - ' +
                                moment(schedule.end_time).format('MMM Do, h:mm a');
                            $scope.available_schedules.push({
                                id: schedule.id,
                                label: label
                            });
                        });
                        $scope.getSubjects();
                    })
                    .catch(function(r)
                    {
                    });
            };
            $scope.$watch('subjects',function(newVal, oldVal)
            {
                $scope.getMatchingSubjects(newVal);
            },true);
            $scope.submit = function()
            {
                api
                    .postAddCallLimited(
                        {
                            call:
                            {
                                info:$scope.intake_call,
                                subjects:$scope.subjects,
                                volunteer:$scope.volunteer_schedule_id
                            }
                        }
                    )
                    .then(function(response)
                    {
                        $location.path('/thanks');
                    })
                    .catch(function(response)
                    {
                        SweetAlert.swal('Error', 'There was an error saving the call!\n' + response.error,"error");
                    });
            };
            /* Helpers */
            $scope.getStatus = function()
            {
                var status = $scope.intake_call.status;
                if(status == '1')
                    return 'New';
                if(status == '2')
                    return 'In Progress';
                if(status == '3')
                    return '(Left Message)';
                if(status == '4')
                    return 'Closed';
                if(status === '5')
                    return '(Called Back)';
                if(status === '6')
                    return '(Could Not Contact)';
                return '';
            };
            // New function to pretty print the contact value
            $scope.getContact = function()
            {
                var contact = $scope.intake_call.contact;
                if(contact === '1')
                    return '(Phone Call)';
                if(contact === '2')
                    return '(Sent Email)';
                if(contact === '3')
                    return '(Left Voicemail)';
                if(contact === '4')
                    return '(Unable to Contact)';
                if(contact === '5')
                    return '(Other)';
                return '';
            };
            $scope.getSubjects = function($subjects)
            {
                angular.forEach($subjects, function(value, key)
                {
                    $scope.subjects[key] = value == 1 ? true : false;
                },true);
            };
            $scope.getPrettyCallBackTime = function()
            {
                if($scope.intake_call.volunteer_schedule)
                    return moment($scope.intake_call.volunteer_schedule.start_time).format('MMM-D h:mm A')
                        + ' - '
                        + moment($scope.intake_call.volunteer_schedule.end_time).format('h:mm A');
                return false;
            };
            // Returns true if at least one subject is true
            $scope.SubjectHasTruth = function()
            {
                var result = false;
                angular.forEach($scope.subjects, function(value, key)
                {
                    if(value) {
                        result =  true;
                    }
                });
                return result;
            };
            $scope.convertIntakeValuesToString = function()
            {
                // We need to convert the INT values to string, for SELECT OPTION
                var call_intake = $scope.intake_call;
                call_intake.how_ship = call_intake.how_ship.toString();
                call_intake.continuing_contact = call_intake.continuing_contact.toString();
                call_intake.age_group = call_intake.age_group.toString();
                call_intake.gender = call_intake.gender.toString();
                call_intake.primary_language = call_intake.primary_language.toString();
                call_intake.income = call_intake.income.toString();
                call_intake.assets = call_intake.assets.toString();
                call_intake.apply_receive_ssd_md = call_intake.apply_receive_ssd_md.toString();
                call_intake.dual_eligible = call_intake.dual_eligible.toString();
                call_intake.contact = call_intake.contact.toString(); // Convert contact to string
                $scope.call_intake = call_intake;
            }
        }
    ]);
})(window.skeletonApp);
