'use strict';
(function (app) {
    app.controller('EventCtrl', [
        '$scope', '$routeParams', 'api', 'alerts', 'SweetAlert', 'uiCalendarConfig',
        function ($scope, $routeParams, api, alerts, SweetAlert, uiCalendarConfig) {
            $scope.current_events = [];
            $scope.event_data = {
                event_blocks: []
            };
            $scope.loading_events = false;
            $scope.counties = [];
            $scope.selectedCountyId = ''; // Initialize with empty value for all counties

            // Define colors for each county based on name
            const countyColors = {
                'LIVINGSTON': '#1E90FF',
                'MACOMB': '#32CD32',
                'MONROE': '#FFD700',
                'OAKLAND': '#FF4500',
                'ST. CLAIR': '#8A2BE2',
                'WASHTENAW': '#FF69B4',
                'WAYNE': '#FF6347',
                'Other': '#808080'
            };

            // Function to get color based on county name
            $scope.getCountyColor = function(countyName) {
                // Ensure the name is properly formatted
                const formattedName = countyName.toUpperCase();
            
                // Handle the "Other" case explicitly
                if (formattedName === "OTHER") {
                    return countyColors['Other'];
                }
            
                // Return the color for the given county name or a default color
                return countyColors[formattedName] || '#039BE5'; // Default color if not found
            };

            // Function to match county ID to county name
            $scope.getCountyNameById = function(countyId) {
                const county = $scope.counties.find(county => county.id == countyId);
                return county ? county.name : 'Unknown';
            };

            // Load counties first
            api.getAllCounties().then(function (result) {
                // The structure seems to have counties directly in the response
                if (result && Array.isArray(result.counties)) {
                    $scope.counties = result.counties;
                    console.log('Counties loaded:', $scope.counties);

                    // After counties are loaded, fetch events
                    $scope.refreshEvents();
                } else {
                    console.error('Unexpected API response structure:', result);
                }
            }).catch(function (error) {
                console.error('Error loading counties:', error);
            });

            $scope.registerForEvent = function (event_data) {
                alerts.eventRegister(event_data, $scope.current_user.volunteer.id, function () { $scope.refreshEvents(); });
            };

            if ($routeParams.event_id) {
                $scope.registerForEvent({ id: $routeParams.event_id });
            }

            $scope.refreshEvents = function () {
                uiCalendarConfig.calendars.myCalendar.fullCalendar('refetchEvents');
            };

            $scope.getAllEvents = function (start, end, timezone, callback) {
                $scope.current_events.length = 0;

                const params = {
                    start_time: start.format(),
                    end_time: end.format()
                };

                if ($scope.selectedCountyId) {
                    params.county_id = $scope.selectedCountyId;
                }

                api.getAllEvents(params)
                    .then(function (result) {
                        result.events.forEach(event => {
                            const countyName = $scope.getCountyNameById(event.county_id);
                            let color = $scope.getCountyColor(countyName);

                            event.event_blocks.forEach(event_block => {
                                event_block.event_volunteers.forEach(volunteer => {
                                    if (volunteer.volunteer_id == $scope.current_user.volunteer.id) {
                                        event.registered = true;
                                    }
                                });
                                if (event_block.volunteers_needed <= event_block.event_volunteers.length) {
                                    event.full = true;
                                } else {
                                    event.full = false;
                                }
                            });

                            if (event.registered) color = '#4CAF50';
                            if (event.full && !event.registered) color = '#FF5722';

                            $scope.current_events.push({
                                id: event.id,
                                title: event.name,
                                start: event.date,
                                color: color,
                                event_blocks: event.event_blocks
                            });
                        });
                        callback($scope.current_events);
                    })
                    .catch(function (result) {
                        // Handle error
                    });
            };

            $scope.filterByCounty = function () {
                console.log('Filtering by county:', $scope.selectedCountyId);
                $scope.refreshEvents();
            };

            // Calendar configuration
            $scope.uiConfig = {
                calendar: {
                    editable: false,
                    selectable: false,
                    eventLimit: true,
                    allDaySlot: false,
                    height: 680,
                    minTime: "08:00:00",
                    maxTime: "20:00:00",
                    header: {},
                    eventClick: $scope.registerForEvent,
                    loading: function (loading) { $scope.loading_events = loading; },
                    events: $scope.getAllEvents
                },
            };
        }
    ]);
})(window.skeletonApp);
