(function (app) {
    app.controller('ArchivedCallsCtrl', [
        '$scope', 'api', 'Upload', '$timeout', 'SweetAlert', 'alerts',
        function ($scope, api, Upload, $timeout, SweetAlert, alerts) {
            $scope.archived_calls = [];
            $scope.displayed = [];
            $scope.getArchivedCalls = function (tablestate) {
                // console.log(typeof($scope.tablestate));
                // console.log($scope.tablestate);
                if(typeof(tablestate) !== "undefined") {
                    $scope.tablestate = tablestate;
                    $scope.isLoading = true;
                    api
                        .getArchivedCalls(tablestate)
                        .then(function (response) {
                            $scope.archived_calls = response.calls;
                        })
                        .catch(function (response) {
                            SweetAlert.swal('Error', response, 'error');
                        });
                }
            };
            $scope.openArchiver = function () {
                alerts.callArchiver(function(){
                    $scope.getArchivedCalls($scope.tablestate);
                });
            };
            $scope.formatDate = function (date) {
                return moment(date).format('MM/DD/YYYY');
            };
            $scope.viewCall = function (call_data) {
                alerts
                    .callRegister(call_data, $scope.current_user.volunteer.id, function ($uiModal, call_data) {
                            call_data.volunteer_id = $scope.current_user.volunteer.id;
                            api
                                .postUpdateCallbackTime(call_data)
                                .then(function (response) {
                                    SweetAlert.swal('Success', 'You have registered to this call!', 'success');
                                    $uiModal.close();
                                    $scope.getArchivedCalls($scope.tablestate);
                                })
                                .catch(function (response) {
                                    SweetAlert.swal('Error', response, 'error');
                                });
                        }
                    );
            };
            $scope.archived_calls = [];
            $scope.displayed = [];
            $scope.my_time = [];
            $scope.getArchivedCalls($scope.tablestate);
        }
    ]);
    app.controller('CallArchiverCtrl', [
        '$scope','api', '$uibModalInstance', 'SweetAlert','archive_call_callback',
        function($scope,api, $uibModalInstance, SweetAlert, archive_call_callback) {
        $scope.archiveCalls = function () {
            console.log($scope.start_date + $scope.end_date);
            if( ! typeof($scope.start_date) || ! typeof($scope.end_date)) {
                SweetAlert.swal("Error","Both dates are required.","error");
            }
            api.archiveCallsBetween($scope.start_date, $scope.end_date)
                .then(function(data) {
                    if(data.count > 0) {
                        SweetAlert.swal('Success', data.count + " Calls were archived successfully");
                    } else {
                        SweetAlert.swal('Error', 'No calls were received in that time period', 'error');
                    }
                })
                .catch(function(response) {
                    SweetAlert.swal("Error",response,"error");
                });
        };
        $scope.close = function () {
            console.log('closing');
            $uibModalInstance.close();
        }
        }]);
})(window.skeletonApp);
