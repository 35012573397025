(function(app) {
    app.controller('ViewUsersCtrl', [
        '$scope','api','SweetAlert','$location', 'alerts',
        function($scope, api, SweetAlert,$location, alerts)
        {
            $scope.users = [];
            $scope.displayed = [];
            $scope.users_per_page = 5;
            $scope.isLoading = false;
            $scope.user_type_search = '';
            $scope.$watch('user_type_search', function()
            {
                $scope.getUsers($scope.tablestate);
            });
            $scope.getUsers = function(tablestate)
            {
                $scope.isLoading = true;
                if(!tablestate)
                    return;
                tablestate.search.user_type = $scope.user_type_search;
                $scope.tablestate = tablestate;
                api
                    .postSearchUsers(tablestate)
                    .then(function(results)
                    {
                        
                        $scope.isLoading = false;
                        $scope.displayed = [].concat(results.users);
                        tablestate.pagination.numberOfPages = Math.ceil(results.total / tablestate.pagination.number);
                    })
                    .catch(function(results)
                    {
                        SweetAlert.swal('Error',results,'error');
                    });
            };
            $scope.viewUser = function(id)
            {
                $location.path('/users/view-users/'+id);
            };
            $scope.deleteUser = function(id)
            {
                alerts.confirm("Are you sure?",
                    "Are you sure you want to delete this User?",
                    function(){
                        api.postDeleteUser({id: id})
                            .then(function() {
                                $scope.getUsers($scope.tablestate);
                            })
                            .catch(function(response) {
                                console.error(response.error);
                            });
                     }, function() {
                       return;
                });
            };
            $scope.userFormat = function(user)
            {
                if(user === 'admin')
                    return 'Admin';
                if(user === 'volunteer')
                    return 'Volunteer';
                if(user === 'site_coord')
                    return 'Site Coordinator';
                if(user === 'call_center')
                    return 'Call Center';
            }
        }
    ])
})(window.skeletonApp);