'use strict';

(function ()
{
    window.skeletonApp = angular.module('skeletonApp', [
        'ngRoute',
        'ngSanitize',
        'ui.bootstrap',
        'smart-table',
        'monospaced.elastic',
        'internationalPhoneNumber',
        'CompiledTemplates',
        'angular.filter',
        'oitozero.ngSweetAlert',
        'ui.calendar',
        'ngCsv',
        'isteven-multi-select',
        'chart.js',
        'ngFileUpload',
        'ui.toggle',
        'ngStorage'
    ]).config([
        '$routeProvider', '$locationProvider',
        function ($routeProvider, $locationProvider)
        {
            $routeProvider.when('/', {
                redirectTo: '/home'
            });

            $routeProvider.when('/home', {
                templateUrl: '/templates/home.html',
                controller: 'HomeCtrl'
            });

            $routeProvider.when('/login', {
                templateUrl: '/templates/login.html',
                controller: 'LoginCtrl'
            });
            $routeProvider.when('/call-intake',
            {
                templateUrl:'/templates/call-intake.html',
                controller:'CallIntakeCtrl'
            });
            $routeProvider.when('/call-intake/:call_id',
            {
                templateUrl:'/templates/call-intake.html',
                controller:'CallIntakeCtrl'

            });
            $routeProvider.when('/reports',
            {
                templateUrl:'/templates/reports.html',
                controller:'ReportCtrl'
            });
            $routeProvider.when('/event-calendar',
            {
                templateUrl:'/templates/event-calendar.html',
                controller:'EventCtrl'
            });
            $routeProvider.when('/event-calendar/:event_id',
            {
                templateUrl:'/templates/event-calendar.html',
                
            });
            $routeProvider.when('/call-queue',
                {
                    templateUrl:'/templates/call-queue.html',
                    controller:'CallQueueCtrl'
                }
            );
            $routeProvider.when('/call-queue/pending',
                {
                    templateUrl:'/templates/call-queue.html',
                    controller:'CallQueueCtrl'
                }
            );
            $routeProvider.when('/call-queue/completed',
                {
                    templateUrl:'/templates/call-queue.html',
                    controller:'CallQueueCtrl'
                }
            );
            $routeProvider.when('/call-queue/unsassigned',
                {
                    templateUrl:'/templates/call-queue.html',
                    controller:'CallQueueCtrl'
                }
            );
            $routeProvider.when('/call-import',
                {
                    templateUrl:'/templates/admin/call-import.html',
                    controller:'CallImportCtrl'
                }
            );
            $routeProvider.when('/archived-calls',
                {
                    templateUrl:'/templates/admin/archived-calls.html',
                    controller:'ArchivedCallsCtrl'
                }
            );
            $routeProvider.when('/call-queue/:call_id',
                {
                    templateUrl:'/templates/call-queue.html',
                    controller:'CallQueueCtrl'
                }
            );
            //Volunteer Calls
            $routeProvider.when('/calls',
                {
                    redirectTo:'/calls/my-calls'
                }
            );
            $routeProvider.when('/calls/my-calls',
                {
                    redirectTo:'/calls/my-calls/pending'
                }
            );
            $routeProvider.when('/calls/my-calls/pending',
                {
                    templateUrl:'/templates/call-queue.html',
                    controller:'CallQueueCtrl'
                }
            );
            $routeProvider.when('/calls/my-calls/:call_id',
                {
                    templateUrl:'/templates/call-queue.html',
                    controller:'CallQueueCtrl'
                }
            );
            $routeProvider.when('/calls/unassigned',
                {
                    templateUrl:'/templates/calls-unassigned.html',
                    controller:'CallsUnassignedCtrl'
                }
            );
            $routeProvider.when('/calls/old',
                {
                    templateUrl:'/templates/calls-old.html',
                    controller:'CallsOldCtrl'
                }
            );

            $routeProvider.when('/volunteer-schedule',
            {
                templateUrl:'/templates/volunteer-schedule.html',
                controller:'VolunteerScheduleCtrl'
            });

            $routeProvider.when('/users',
            {
                redirectTo:'/users/view-users'
            });

            $routeProvider.when('/users/adjust-time',
            {
                templateUrl:'/templates/admin/view-user-times.html',
                controller:'VolunteerTimeEditorCtrl'
            });

            $routeProvider.when('/users/edit-time/:volunteer_id',
                {
                    templateUrl:'/templates/volunteer-schedule.html',
                    controller:'VolunteerTimeEditorCtrl'
                });
            $routeProvider.when('/users/add-user',
            {
                templateUrl:'/templates/add-user.html',
                controller:'UserModCtrl'
            });
            $routeProvider.when('/users/view-users/:user_id',
            {
                templateUrl:'/templates/add-user.html',
                controller:'UserModCtrl'
            });
            $routeProvider.when('/users/view-users',
            {
                templateUrl:'/templates/view-users.html',
                controller:'ViewUsersCtrl'
            });
            $routeProvider.when('/password-change',
                {
                    controller: 'ChangePasswordAlertCtrl',
                    templateUrl: '/templates/password-reset.html'
                });
            $routeProvider.when('/password-reset/:user_email&:password_hash',
            {
                templateUrl:'/templates/password-reset.html',
                controller:'PasswordResetController'
            });
            $routeProvider.when('/web-call-intake',
            {
                templateUrl:'/templates/call-intake-limited.html',
                controller:'CallIntakeLimitedCtrl'
            });
            $routeProvider.when('/thanks',
            {
                templateUrl:'/templates/thanks.html',
                controller:
                [
                    '$scope','$location',
                    function($scope, $location)
                    {
                        $scope.new_call = function(){ $location.path('/web-call-intake');}
                    }
                ]
            });
            $routeProvider.when('/logout', {
                controller: [
                    '$scope', 'api',
                    function($scope, api)
                    {
                        api.revokeAuthentication()
                            .then(function()
                            {
                                $scope.orginal_location = '/home';
                                $scope.$emit('authenticated', false);
                            })
                            .catch(function(r)
                            {
                                ('error', r);
                            });
                    }
                ],
                template: '<div class="container"><div class="col-xs-12 text-center"><h1>Please Wait</h1><p>We are revoking your authentication.</p></div></div>'
            });

            $routeProvider.otherwise({
                controller: [
                    '$scope', 'alerts', "$location",
                    function ($scope, alerts, $location)
                    {
                        alerts
                            .error('Page Not Found', 'Something went wrong!!! You got to this page, ' +
                                'but I do not know how serve it to you. Please contact your support ' +
                                'person.',
                            function (){
                                if($scope.is_authenticated)
                                    $location.path('/home');
                                else {
                                    $scope.password_reset = false;
                                    $location.path('/login');
                                }
                            });
                    }
                ],
                template: '<div></div>'
            });

            $locationProvider.html5Mode(true);
        }
    ]).directive('closeSidebar', [function() {
        return {
            restrict: 'A',
            scope: {
                'model': '='
            },
            link: function(scope, elem, attrs) {

                elem.bind('click', function()
                {
                    if($(window).width() <= 767)
                    {
                        $('#sidebarLeft').toggleClass('sidebar-visible');
                    }
                });
            }
        };
    }])
        .directive('mask', [function() {
            return {
                restrict:'A',
                scope:true,
                link : function (scope, elem, attrs)
                {
                    $(elem).mask(attrs.masktype,{
                        translation: {
                            '#': {
                                pattern:'/[0-9]/',
                                fallback:'#'
                            }
                        },
                        recursive:true
                    });

                    scope.$watch(
                        function () { return elem[0].value; },
                        function (newValue, oldValue) {
                           scope.intake_call.caller_phone = $(elem).masked(newValue);

                        }
                    );
                }
            }
        }])
        .directive('maskP', [function() {
            return {
                restrict:'A',
                scope:true,
                link : function (scope, elem, attrs)
                {
                    $(elem).mask(attrs.masktype,{
                        translation: {
                            '#': {
                                pattern:'/[0-9]/',
                                fallback:'#'
                            }
                        },
                        recursive:true
                    });

                    scope.$watch(
                        function () { return elem[0].value; },
                        function (newValue, oldValue) {
                            scope.call_data.caller_phone = $(elem).masked(newValue);

                        }
                    );
                }
            }
        }]).directive('date', [function() {
            return {
                restrict:'A',
                scope:true,
                link : function (scope, elem, attrs)
                {
                    $(elem).datepicker({autoclose:true});
                }
            }
        }]).directive('time', [function() {
            return {
                restrict:'A',
                scope:true,
                link : function (scope, elem, attrs)
                {
                    $(elem).timepicker({autoclose:true});
                }
            }
        }]);
})();

