(function(app) {
    app.controller('AdminHomeCtrl', ['$scope', 'api', 'SweetAlert', function($scope, api, SweetAlert) {
        $scope.all_events = [];
        $scope.all_calls = [];
        $scope.users = [];
        $scope.selectedUser = null;
        $scope.isImpersonating = false;

        // Fetch all users
        api.getAllUsers().then(function(response) {
            console.log('getAllUsers response:', response);
            if (response && response.users) {
                $scope.users = response.users;
            } else {
                SweetAlert.swal('Error', 'Unexpected response structure!', 'error');
            }
        }).catch(function(error) {
            SweetAlert.swal('Error', 'Unable to fetch users!', 'error');
        });

        // Check impersonation state
        api.getImpersonationState().then(function(response) {
            console.log('Impersonation state response:', response);
            if (response && typeof response.isImpersonating !== 'undefined') {
                $scope.isImpersonating = response.isImpersonating;
            } else {
                console.error('Unexpected response structure:', response);
            }
        }).catch(function(error) {
            SweetAlert.swal('Error', 'Unable to get impersonation state!', 'error');
        });

        // Impersonate user
        $scope.impersonateUser = function() {
            if ($scope.selectedUser) {
                api.impersonateUser($scope.selectedUser).then(function(response) {
                    console.log('Impersonate user response:', response);
                    if (response && response.success) {
                        SweetAlert.swal('Success', response.message || 'Impersonation successful', 'success');
                        $scope.isImpersonating = true;
                        location.reload();
                    } else {
                        SweetAlert.swal('Error', response.message || 'Impersonation failed', 'error');
                    }
                }).catch(function(error) {
                    SweetAlert.swal('Error', 'Unable to impersonate user!', 'error');
                    console.error('Impersonate user error:', error);
                });
            }
        };

        // Stop impersonating user
        $scope.stopImpersonating = function() {
            api.stopImpersonating().then(function(response) {
                console.log('Stop impersonating response:', response);
                if (response && response.success) {
                    SweetAlert.swal('Success', response.message || 'You have stopped impersonating.', 'success');
                    $scope.isImpersonating = false;
                    location.reload();
                } else {
                    SweetAlert.swal('Error', response.message || 'Unable to stop impersonating user!', 'error');
                }
            }).catch(function(error) {
                SweetAlert.swal('Error', 'Unable to stop impersonating user!', 'error');
                console.error('Stop impersonating error:', error);
            });
        };

        // Get call data
        $scope.getCallData = function() {
            api.getCallDataByDates({
                start: $scope.start_date,
                end: $scope.end_date
            }).then(function(response) {
                console.log('getCallDataByDates response:', response);
                $scope.labels = ['Open', 'Completed', 'All'];
                $scope.pie_labels = ['Open', 'Completed'];
                $scope.data = [response.open_calls, response.completed_calls, response.all_calls];
                $scope.pie_data = [response.open_calls, response.completed_calls];
            }).catch(function(error) {
                console.error('Error fetching call data:', error);
            });
        };

        // Initial data load
        $scope.getCallData();

        // Get all events
        api.getAllEventsUpcoming().then(function(response) {
            console.log('getAllEventsUpcoming response:', response);
            angular.forEach(response.events, function(event) {
                event.date = moment(event.date).format('MMMM D, h:mm A');
                for (var i = 0; i < event.event_blocks.length; i++) {
                    var event_block = event.event_blocks[i];
                    event_block.start_time = moment(event_block.start_time).format('h:mm A');
                    event_block.end_time = moment(event_block.end_time).format('h:mm A');
                }
                $scope.all_events.push(event);
            });
        }).catch(function(response) {
            SweetAlert.swal('Error', 'There was an error obtaining events! Please try again!', 'error');
        });

        api.getAllCallsToday().then(function(response) {
            console.log('getAllCallsToday response:', response);
            $scope.all_calls = response.calls;
        }).catch(function(response) {
            console.error('Error fetching calls:', response);
        });

        $scope.isCompletedCall = function(call) {
            return call.completed_date != '0000-00-00 00:00:00';
        };

        $scope.openGoogleMaps = function(location_name, location_address) {
            window.open('https://maps.google.com/?q=' + location_name + ',' + location_address, '_blank', '');
        };
    }]);
})(window.skeletonApp);







