'use strict';
(function (app) {
    app.controller('CallInfoCtrl', [
        '$scope', 'SweetAlert', '$uibModalInstance', '$timeout', 'call_data', 'resolve_callback', 'un_volunteer_call', 'update_callback', 'api', '$location',
        function ($scope, SweetAlert, $uibModalInstance, $timeout, call_data, resolve_callback, un_volunteer_call, update_callback, api, $location) {
            api.getCurrentUser().then(function (response) {
                $scope.current_user = response.user;
                console.log("Current user:", $scope.current_user);

                if ($scope.original_path === '/login') {
                    $scope.original_path = '/home';
                }
                $location.path($scope.original_path).replace();
            }).catch(function () {
                SweetAlert.swal('Error', 'There was an error logging you in! Please try again!', 'error');
                $location.path('/logout');
            });

            api.getAllCounties().then(function (result) {
                $scope.counties = result.counties;
                console.log("Counties:", $scope.counties);

                for (var i = 0, len = $scope.counties.length; i < len; i++) {
                    if ($scope.counties[i].name === "Declined") {
                        var item = $scope.counties[i];
                        $scope.counties.sort();
                        $scope.counties.splice(i, 1);
                        $scope.counties.splice(0, 0, item);
                    }
                }
            }).catch(function () {
                SweetAlert.swal('Error', 'There was an issue grabbing all counties! Please refresh the page!', 'error');
            });

            api.getSiteCoordinators().then(function (result) {
                var site_coordinators = [{ id: 0, name: 'Default Site Coordinator' }];
                site_coordinators = site_coordinators.concat(result.site_coordinators);
                $scope.site_coordinators = site_coordinators;
                console.log("Site coordinators:", $scope.site_coordinators);
            }).catch(function () {
                SweetAlert.swal('Error', 'Unable to grab site coordinators! Please refresh the page!', 'error');
            });

            api.getAllVolunteerUsers().then(function (res) {
                $scope.volunteer_users = res;
                console.log("Volunteer users:", $scope.volunteer_users);
            }).catch(function (error) {
                SweetAlert.swal("Error", error, "error");
            });

            $scope.call_data = call_data || {};
console.log("Initial call_data:", $scope.call_data);

// Ensure time_spent exists on the call_data object
if (typeof $scope.call_data.time_spent !== 'undefined' && Number($scope.call_data.time_spent) > 2) {
    $scope.time_spent_override = Number($scope.call_data.time_spent);
    $scope.call_data.time_spent = '-1';
} else {
    $scope.call_data.time_spent = $scope.call_data.time_spent || '0';
}

if ($scope.call_data.best_time) {
    try {
        $scope.call_data.best_time = JSON.parse($scope.call_data.best_time);
    } catch (e) {
        $scope.call_data.best_time = {
            morning: false,
            afternoon: false,
            evening: false
        };
    }
} else {
    $scope.call_data.best_time = {
        morning: false,
        afternoon: false,
        evening: false
    };
}

// Handle contact history and map contact method numbers to names
const contactMethodMap = {
    1: 'Phone Call',
    2: 'Sent Email',
    3: 'Left Voicemail',
    4: 'Unable to Contact',
    5: 'Other'
};

if ($scope.call_data.contact) {
    try {
        $scope.contact_history = JSON.parse($scope.call_data.contact).map(method => contactMethodMap[method]);
    } catch (e) {
        $scope.contact_history = [];
    }
} else {
    $scope.contact_history = [];
}

// Check if call_data.subjects is defined and initialize subjects
$scope.call_data.subjects = $scope.call_data.subjects || {
    new_to_medicare: false,
    original_medicare: false,
    prescription_drug_coverage: false,
    medicare_advantage: false,
    medigap: false,
    medicare_medicaid_dual: false,
    spenddown: false,
    medicare_savings_program: false,
    extra_help_lis: false,
    fraud_abuse_scams: false,
    long_term_care: false,
    billing_issues: false,
    appeals: false,
    disability: false,
};

$timeout(function () {
    $scope.subjects = $scope.call_data.subjects;
    console.log("Initialized subjects:", $scope.subjects);
});


            $scope.getMatchingSubjects = function (subjects) {
                $scope.loading_volunteers = true;
                $scope.available_schedules = [];
            
                console.log("Sending subjects to API:", subjects);
            
                api.getScheduleBySubjects({ subjects: subjects }).then(function (data) {
                    console.log("API response:", data);
                    if (data && data.volunteer_schedules) {
                        angular.forEach(data.volunteer_schedules, function (schedule) {
                            var label = moment(schedule.start_time).format('MMM Do, h:mm a') + ' - ' + moment(schedule.end_time).format('MMM Do, h:mm a');
                            $scope.available_schedules.push({
                                id: schedule.id,
                                label: label
                            });
                        });
                    } else {
                        console.error("Unexpected API response:", data);
                        SweetAlert.swal('Error', 'Unexpected response from server. Please try again.', 'error');
                    }
                }).catch(function (error) {
                    console.error("Error fetching schedules by subjects", error);
                    SweetAlert.swal('Error', 'Failed to fetch schedules. Please check your permissions and try again.', 'error');
                });
            };

            $scope.$watch('subjects', function (newVal, oldVal) {
                if (newVal) {
                    console.log("Subjects updated:", newVal);
                    $scope.getMatchingSubjects(newVal);
                }
            }, true);

            $scope.selected_volunteer = [];
            $scope.assignVolunteer = function (call_id) {
                if (!$scope.selected_volunteer.length) {
                    SweetAlert.swal("Error", "You need to select a volunteer!", "error");
                    return;
                }
            
                const selectedUserId = $scope.selected_volunteer[0].id;
                console.log('Selected User ID:', selectedUserId); // Log the selected user ID
            
                // Fetch all volunteers and find the one that matches the selected user's ID
                api.getAllVolunteerUsers().then(function (res) {
                    console.log('Volunteer Users Data:', res); // Log the volunteer users data
                    
                    // Find the volunteer entry with the matching user_id
                    const volunteerEntry = res.find(volunteer => volunteer.volunteer && volunteer.volunteer.user_id === selectedUserId);
                    
                    if (volunteerEntry && volunteerEntry.volunteer && volunteerEntry.volunteer.id) {
                        const volunteer_id = volunteerEntry.volunteer.id;
                        console.log('Matched Volunteer ID:', volunteer_id); // Log the matched volunteer ID
                        
                        // Submit the matched volunteer_id to the calls table
                        api.postAddVolunteer(call_id, volunteer_id)
                            .then(function (res) {
                                console.log('API Response:', res); // Log the API response
                                if (res.volunteer && res.volunteer.user) {
                                    $scope.call_data.volunteer_user = res.volunteer.user;
                                    SweetAlert.swal({
                                        title: "Success",
                                        text: "Volunteer added to call!",
                                        type: "success"
                                    }, function () {
                                        window.location.reload();
                                    });
                                } else {
                                    console.error("Unexpected API response structure:", res);
                                    SweetAlert.swal("Error", "Unexpected API response structure!", "error");
                                }
                            })
                            .catch(function (error) {
                                console.error("Error adding volunteer:", error);
                                SweetAlert.swal("Error", error, "error");
                            });
                    } else {
                        console.error("No volunteer found for the selected user ID.");
                        SweetAlert.swal("Error", "No volunteer found for the selected user ID!", "error");
                    }
                }).catch(function (error) {
                    console.error("Error retrieving volunteer:", error);
                    SweetAlert.swal("Error", "Failed to retrieve volunteer data!", "error");
                });
            };
            

            $scope.removeVolunteer = function (call_id) {
                SweetAlert.swal({
                    title: "Are you sure?",
                    text: "Are you sure you want to remove this user?",
                    type: "warning",
                    showCancelButton: true
                }, function (confirm) {
                    if (confirm) {
                        api.postRemoveVolunteer(call_id).then(function () {
                            SweetAlert.swal("Success", "Volunteer removed!", "success");
                            $scope.call_data.volunteer_user = null;
                        }).catch(function (error) {
                            SweetAlert.swal("Error", error, "error");
                        });
                    }
                });
            };

            $scope.unVolunteerCall = function (id) {
                SweetAlert.swal({
                    title: 'Are you Sure?',
                    text: 'Are you sure you want to un-volunteer for this call?',
                    type: 'warning',
                    showCancelButton: true,
                    closeOnCancel: true,
                    closeOnConfirm: true
                }, function (confirm) {
                    if (confirm) {
                        un_volunteer_call(id, $uibModalInstance);
                    }
                });
            };

            $scope.resolveCall = function () {
                SweetAlert.swal({
                    title: 'Are you Sure?',
                    text: 'Are you sure you want to mark this call as resolved?',
                    type: 'warning',
                    showCancelButton: true,
                    closeOnCancel: true,
                    closeOnConfirm: true
                }, function (confirm) {
                    if (confirm) {
                        resolve_callback($scope.call_data, $uibModalInstance);
                    }
                });
            };

            $scope.alertVolunteer = function (volunteer_id, call_id) {
                SweetAlert.swal({
                    title: "Are you sure?",
                    text: "Are you sure you want to alert this user?",
                    type: "warning",
                    showCancelButton: true
                }, function (confirm) {
                    if (confirm) {
                        api.postAlertVolunteer(volunteer_id, call_id, 1).then(function (res) {
                            SweetAlert.swal("Success", "User alerted!", "success");
                        }).catch(function (error) {
                            SweetAlert.swal('Error', error, "error");
                        });
                    }
                });
            };

            $scope.delete = function () {
                SweetAlert.swal({
                    title: 'Are you sure?',
                    text: 'Are you sure you want to remove this call?',
                    type: 'warning',
                    showCancelButton: true,
                    closeOnConfirm: true,
                    closeOnCancel: true
                }, function (confirm) {
                    if (confirm) {
                        api.postDeleteCall($scope.call_data.id).then(function (results) {
                            $uibModalInstance.dismiss();
                            SweetAlert.swal('Success', 'Call has been removed!', 'success');
                        }).catch(function (results) {
                            SweetAlert.swal('Error', results, 'error');
                        });
                    }
                });
            };

            $scope.submit = function () {
                SweetAlert.swal({
                    title: 'Are you Sure?',
                    text: 'Are you sure you want to update the call? Remember to enter in SHIPTools',
                    type: 'warning',
                    showCancelButton: true,
                    closeOnConfirm: true,
                    closeOnCancel: true
                }, function (isConfirm) {
                    if (isConfirm) {
                        $timeout(function () {
                            if ($scope.time_spent_override > 0 && $scope.call_data.time_spent === '-1') {
                                $scope.call_data.time_spent = $scope.time_spent_override;
                            }
                            if ($scope.time_spent_override <= 0 && $scope.call_data.time_spent === '-1') {
                                $scope.call_data.time_spent = 0;
                            }
            
                            var updateData = {
                                call: {
                                    info: {
                                        id: $scope.call_data.id,
                                        caller_name: $scope.call_data.caller_name,
                                        caller_phone: $scope.call_data.caller_phone,
                                        address: $scope.call_data.address,
                                        caller_city: $scope.call_data.caller_city,
                                        zip: $scope.call_data.zip,
                                        caller_county_id: $scope.call_data.caller_county_id,
                                        time_spent: $scope.call_data.time_spent,
                                        email: $scope.call_data.email,
                                        contact: $scope.call_data.contact,
                                        priority: $scope.call_data.priority,
                                        best_time: $scope.call_data.best_time,
                                        issue_description: $scope.call_data.issue_description,
                                        notes: $scope.call_data.notes,
                                        status: $scope.call_data.status
                                    },
                                    subjects: $scope.subjects // Include subjects in the update data
                                }
                            };
            
                            console.log("Updating call with data:", updateData);
                            api.postUpdateCall(updateData).then(function (response) {
                                SweetAlert.swal("Success", "Call updated successfully! Remember to enter in SHIPTools", "success");
                                $uibModalInstance.close(response.data);
            
                                // Force page/window refresh after modal close
                                setTimeout(function () {
                                    window.location.reload();
                                }, 1000);
                            }).catch(function (error) {
                                SweetAlert.swal("Error", "Failed to update the call!", "error");
                                console.error("Update Error:", error);
                            });
                        });
                    }
                });
            };
            
            $scope.update_callback = function (updateData, modalInstance) {
                api.postUpdateCall(updateData).then(function (response) {
                    SweetAlert.swal("Success", "Call updated successfully! Remember to enter in SHIPTools", "success");
                    modalInstance.close(response.data);
                }).catch(function (error) {
                    SweetAlert.swal("Error", "Failed to update the call!", "error");
                    console.error("Update Error:", error);
                });
            };

            $scope.prettyTime = function () {
                if (!$scope.time_spent_override)
                    return;
                var time = $scope.time_spent_override.toString().split('.');
                if (!time[1])
                    time[1] = 0;
                var minutes = Number('.' + time[1]);
                return time[0] + ((time[0] > 1) ? ' Hours' : ' Hour') + ' , ' + (minutes * 60) + (((minutes > 1) ? ' Minutes' : ' Minute'));
            };

            $scope.dismiss = function () {
                $uibModalInstance.dismiss();
            };

            $scope.printCall = function () {
                var modalContent = document.querySelector('.modal-content').cloneNode(true);
                var elementsToRemove = modalContent.querySelectorAll('#Volunteer-county_id, .btn, small');
                elementsToRemove.forEach(function (element) {
                    element.style.display = 'none';
                });
                var replaceInputs = function (element, scope) {
                    var inputs = element.querySelectorAll('input, textarea, select');
                    angular.forEach(inputs, function (input) {
                        var model = input.getAttribute('ng-model');
                        var value = scope.$eval(model);
                        if (value) {
                            var labelElement = input.previousElementSibling;
                            var labelText = labelElement ? labelElement.innerText : '';
                            if (labelElement) {
                                labelElement.style.display = 'none';
                            }
                            var label = document.createElement('div');
                            label.className = 'label';
                            label.innerText = labelText;
                            var textNode = document.createElement('div');
                            textNode.className = 'value';
                            if (input.tagName === 'SELECT') {
                                var selectedOption = input.querySelector('option:checked');
                                textNode.innerText = selectedOption ? selectedOption.text : '';
                            } else {
                                textNode.innerText = value;
                            }
                            input.parentNode.replaceChild(textNode, input);
                            if (labelText) {
                                textNode.parentNode.insertBefore(label, textNode);
                            }
                        } else {
                            input.parentNode.style.display = 'none';
                        }
                    });
                };
                var replaceTrueWithCheckmark = function (element) {
                    var htmlContent = element.innerHTML;
                    htmlContent = htmlContent.replace(/true/g, '&#10003;');
                    element.innerHTML = htmlContent;
                };
                replaceInputs(modalContent, $scope);
                replaceTrueWithCheckmark(modalContent);
                var printContainer = document.createElement('div');
                printContainer.appendChild(modalContent);
                var printWindow = window.open('', '', 'height=700,width=900');
                printWindow.document.write('<html><head><title>Print Call</title>');
                printWindow.document.write('<style>');
                printWindow.document.write(`
                    body {
                        font-family: Arial, sans-serif;
                        padding: 20px;
                    }
                    .modal-content {
                        border: 1px solid #ddd;
                        padding: 20px;
                    }
                    .modal-header, .modal-body, .modal-footer {
                        margin-bottom: 20px;
                    }
                    .form-group {
                        margin-bottom: 15px;
                    }
                    .form-control {
                        display: block;
                        width: 100%;
                        padding: .375rem .75rem;
                        font-size: 1rem;
                        line-height: 1.5;
                        color: #495057;
                        background-color: #fff;
                        background-clip: padding-box;
                        border: 1px solid #ced4da;
                        border-radius: .25rem;
                        transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
                    }
                    .text-help {
                        display: block;
                        margin-top: .25rem;
                        font-size: 80%;
                        color: #6c757d;
                    }
                    .btn {
                        display: none;
                    }
                    .label {
                        font-weight: bold;
                        margin-top: 10px;
                    }
                    .value {
                        margin-bottom: 10px;
                    }
                `);
                printWindow.document.write('</style>');
                printWindow.document.write('</head><body>');
                printWindow.document.write(printContainer.innerHTML);
                printWindow.document.write('</body></html>');
                printWindow.document.close();
                printWindow.onload = function () {
                    printWindow.print();
                    printWindow.close();
                };
            };
        }
    ]);
})(window.skeletonApp);
