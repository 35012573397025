'use strict';
(function (app) {
    app.controller('CallIntakeCtrl', [
        '$scope', '$route', 'api', 'SweetAlert', '$routeParams', '$location', '$timeout',
        function ($scope, $route, api, SweetAlert, $routeParams, $location, $timeout) {
            $scope.time_spent_override = 0;

            // Get all the counties
            (function () {
                api.getAllCounties()
                    .then(function (result) {
                        $scope.counties = result.counties;
                        for (var i = 0, len = $scope.counties.length; i < len; i++) {
                            if ($scope.counties[i].name === "Declined") {
                                var item = $scope.counties[i];
                                $scope.counties.sort();
                                $scope.counties.splice(i, 1);
                                $scope.counties.splice(0, 0, item);
                            }
                        }
                    })
                    .catch(function () {
                        SweetAlert.swal('Error', 'There was an issue grabbing all counties! Please refresh the page!', 'error');
                    });
                api.getSiteCoordinators()
                    .then(function (result) {
                        var site_coordinators = [{ id: 0, name: 'Default Site Coordinator' }];
                        site_coordinators = site_coordinators.concat(result.site_coordinators);
                        $scope.site_coordinators = site_coordinators;
                    })
                    .catch(function () {
                        SweetAlert.swal('Error', 'Unable to grab site coordinators! Please refresh the page!', 'error');
                    });
                api.getAllVolunteerUsers().then(function (res) {
                    console.log('Volunteer Users Data:', res); 
                    $scope.volunteer_users = res;
                }).catch(function (error) {
                    SweetAlert.swal("Error", error, "error");
                });
            })();

            $scope.intake_call = {
                how_ship: '9',
                continuing_contact: '1',
                age_group: '',
                gender: '9',
                primary_language: '2',
                income: '9',
                assets: '9',
                apply_receive_ssd_md: '9',
                dual_eligible: '9',
                action: 'A',
                time_spent: "0.0",
                site_coordinator_id: 0,
                contact: '1',
                priority: '',
                best_time: {
                    morning: false,
                    afternoon: false,
                    evening: false
                },
                email: ''
            };

            $scope.subjects = {
                new_to_medicare: false,
                original_medicare: false,
                prescription_drug_coverage: false,
                medicare_advantage: false,
                medigap: false,
                medicare_medicaid_dual: false,
                spenddown: false,
                medicare_savings_program: false,
                extra_help_lis: false,
                fraud_abuse_scams: false,
                long_term_care: false,
                billing_issues: false,
                appeals: false,
                disability: false,
                other: ''
            };

            $scope.available_schedules = [];
            $scope.loading_volunteers = false;
            $scope.volunteer_schedule_id = null;
            $scope.selected_volunteer = [];

            $scope.assignVolunteer = function (call_id) {
                if (!$scope.selected_volunteer.length) {
                    SweetAlert.swal("Error", "You need to select a volunteer!", "error");
                    return;
                }
                
                const selectedVolunteer = $scope.selected_volunteer[0];
                
                console.log('Selected Volunteer:', selectedVolunteer); // Log the entire selected volunteer object
            
                // Check if the selected volunteer has the volunteer property and it has an id
                if (!selectedVolunteer.volunteer) {
                    console.error("Selected volunteer does not have a 'volunteer' property.");
                    SweetAlert.swal("Error", "Selected user does not have a valid volunteer property!", "error");
                    return;
                }
                
                if (!selectedVolunteer.volunteer.id) {
                    console.error("Selected volunteer does not have a valid volunteer ID.");
                    SweetAlert.swal("Error", "Selected user does not have a valid volunteer ID!", "error");
                    return;
                }
            
                const volunteer_id = selectedVolunteer.volunteer.id;
                console.log('Volunteer ID:', volunteer_id); // Log the volunteer ID
            
                api.postAddVolunteer(call_id, volunteer_id)
                    .then(function (res) {
                        console.log('API Response:', res); // Log the API response
                        if (res.volunteer && res.volunteer.user) {
                            $scope.intake_call.volunteer_user = res.volunteer.user;
                            SweetAlert.swal({
                                title: "Success",
                                text: "Volunteer added to call!",
                                type: "success"
                            }, function() {
                                window.location.reload();
                            });
                        } else {
                            console.error("Unexpected API response structure:", res);
                            SweetAlert.swal("Error", "Unexpected API response structure!", "error");
                        }
                    })
                    .catch(function (error) {
                        console.error("Error adding volunteer:", error);
                        SweetAlert.swal("Error", error, "error");
                    });
            };
            

            $scope.removeVolunteer = function (call_id) {
                SweetAlert.swal({
                    title: "Are you sure?",
                    text: "Are you sure you want to remove this user?",
                    type: "warning",
                    showCancelButton: true
                },
                function (confirm) {
                    if (confirm) {
                        api.postRemoveVolunteer(call_id)
                            .then(function () {
                                SweetAlert.swal("Success", "Volunteer removed!", "success");
                                $scope.intake_call.volunteer_user = null;
                            })
                            .catch(function (error) {
                                SweetAlert.swal("Error", error, "error");
                            });
                    }
                });
            };

            $scope.getMatchingSubjects = function (subjects) {
                $scope.loading_volunteers = true;
                $scope.available_schedules = [];
                api.getScheduleBySubjects({ subjects: subjects })
                    .then(function (data) {
                        angular.forEach(data.volunteer_schedules, function (schedule) {
                            var label = moment(schedule.start_time).format('MMM Do, h:mm a') +
                                ' - ' +
                                moment(schedule.end_time).format('MMM Do, h:mm a');
                            $scope.available_schedules.push({
                                id: schedule.id,
                                label: label
                            });
                        });
                        $scope.getSubjects();
                    })
                    .catch(function () { });
            };

            $scope.$watch('subjects', function (newVal) {
                $scope.getMatchingSubjects(newVal);
            }, true);

            // Function to check if the phone number exists
            $scope.postCheckPhoneNumber = function(phoneNumber) {
                console.log("Checking phone number:", phoneNumber);
                return api.postCheckPhoneNumber(phoneNumber)
                    .then(function(response) {
                        console.log("API response:", response);
                        return response.exists;  // Return the exists property
                    })
                    .catch(function(error) {
                        console.error("Error in phone number check:", error);
                        SweetAlert.swal('Error', error || 'Unable to verify phone number. Please try again!', 'error');
                        return false;  // Return false in case of an error
                    });
            };
            $scope.submit = function() {
                $scope.postCheckPhoneNumber($scope.intake_call.caller_phone)
                .then(function(exists) {
                    console.log("Phone number check result:", exists);
                    if (exists) {
                        console.log("Phone number exists. Showing SweetAlert.");
                        SweetAlert.swal({
                            title: 'Warning',
                            text: 'This phone number has already been used for a call. Remember to enter in SHIPTools',
                            type: 'warning',
                            showCancelButton: true,
                            confirmButtonText: 'Continue',
                            cancelButtonText: 'Cancel'
                        }, function(isConfirm) {
                            if (isConfirm) {
                                console.log("User confirmed to proceed.");
                                proceedWithSubmission();
                            } else {
                                console.log("User canceled the submission.");
                            }
                        });
                    } else {
                        console.log("Phone number does not exist. Proceeding with submission.");
                        proceedWithSubmission();
                    }
                })
                .catch(function(error) {
                    console.error("Error checking phone number:", error);
                    SweetAlert.swal('Error', error || 'Unable to verify phone number. Please try again!', 'error');
                });
            };
            
            function proceedWithSubmission() {
                console.log("Proceeding with submission.");
                if ($scope.intake_call.id) {
                    console.log("Updating existing call.");
                    $scope.update();
                    return;
                }
                if (!$scope.subjects.other_checkbox) {
                    $scope.subjects.other = '';
                }
                api.postAddCall({
                    call: {
                        info: $scope.intake_call,
                        subjects: $scope.subjects,
                        volunteer: $scope.volunteer_schedule_id
                    }
                })
                .then(function(response) {
                    console.log("Call added successfully:", response);
                    $timeout(function() {
                        SweetAlert.swal({
                            title: 'Success',
                            text: 'Your call has been saved! Remember to enter in SHIPTools',
                            type: 'success',
                            showCancelButton: false,
                            closeOnConfirm: true
                        }, function() {
                            $timeout(function() {
                                $scope.$apply(function() {
                                    $location.path('/call-intake/' + response.id);
                                });
                            }, 500); // Adding a small delay before applying location change
                        });
                    }, 100); // Adding a small delay before showing the success alert
                })
                .catch(function(response) {
                    console.error("Error saving the call:", response);
                    SweetAlert.swal('Error', 'There was an error saving the call!\n' + response, "error");
                });
            }
            
            

            $scope.alertVolunteer = function (volunteer_id, call_id) {
                SweetAlert.swal({
                    title: "Are you sure?",
                    text: "Are you sure you want to alert this user?",
                    type: "warning",
                    showCancelButton: true
                },
                function (confirm) {
                    if (confirm) {
                        api.postAlertVolunteer(volunteer_id, call_id, 1)
                            .then(function () {
                                SweetAlert.swal("Success", "User alerted!", "success");
                            })
                            .catch(function (error) {
                                SweetAlert.swal('Error', error, "error");
                            });
                    }
                });
            };

            $scope.update = function () {
                console.log($scope.intake_call);
                $scope.intake_call.updated_at = moment().format();
                if (!$scope.subjects.other_checkbox) {
                    $scope.subjects.other = '';
                }
                api.postUpdateCall({
                    call: {
                        id: $scope.intake_call.id,
                        info: $scope.intake_call,
                        subjects: $scope.subjects,
                        volunteer: $scope.volunteer_schedule_id
                    }
                })
                .then(function () {
                    SweetAlert.swal({
                        title: 'Success',
                        text: 'The call has been updated! Remember to enter in SHIPTools',
                        type: 'success',
                        closeOnConfirm: true
                    },
                    function () {
                        $scope.getCallById($scope.intake_call.id);
                    });
                })
                .catch(function (response) {
                    SweetAlert.swal('Error', response, 'error');
                });
            };

            $scope.delete = function () {
                SweetAlert.swal({
                    title: 'Are you sure?',
                    text: 'Are you sure you want to remove this call?',
                    type: 'warning',
                    showCancelButton: true,
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                function (confirm) {
                    if (confirm)
                        api.postDeleteCall($scope.intake_call.id)
                            .then(function () {
                                SweetAlert.swal('Success', 'Call has been removed!', 'success');
                                $location.path('/call-intake');
                            })
                            .catch(function (response) {
                                SweetAlert.swal('Error', response, 'error');
                            });
                });
            };

            $scope.getCallById = function (id) {
                api.getCallById(id)
                    .then(function (response) {
                        response.call.zip = Number(response.call.zip);
                        response.call.best_time = JSON.parse(response.call.best_time); // Parse the best_time field
                        $scope.intake_call = response.call;
                        if (Number(response.call.time_spent) > 2) {
                            $scope.time_spent_override = Number($scope.intake_call.time_spent);
                            $scope.intake_call.time_spent = '-1';
                        }
                        $scope.getSubjects(response.call.subjects);
                        $scope.convertIntakeValuesToString();
                    })
                    .catch(function (response) {
                        SweetAlert.swal({
                            title: 'Error',
                            type: 'error',
                            text: response,
                        },
                        function () {
                            $location.path('/call-intake');
                        });
                    });
            };

            if ($routeParams.call_id) {
                $scope.getCallById($routeParams.call_id);
            }

            /* Helpers */
            $scope.prettyTime = function () {
                if (!$scope.time_spent_override)
                    return;
                var time = $scope.time_spent_override.toString().split('.');
                if (!time[1])
                    time[1] = 0;
                var minutes = Number('.' + time[1]);
                return time[0] + ((time[0] > 1) ? ' Hours' : ' Hour') + ' , ' + (minutes * 60) + (((minutes * 60) > 1) ? ' Minutes' : ' Minute');
            };

            $scope.getStatus = function () {
                var status = $scope.intake_call.status;
                if (status === '1')
                    return 'New';
                if (status === '2')
                    return 'In Progress';
                if (status === '3')
                    return '(Left Message)';
                if (status === '4')
                    return 'Closed';
                if (status === '5')
                    return '(Called Back)';
                if (status === '6')
                    return '(Could Not Contact)';
                return '';
            };

            $scope.getSubjects = function ($subjects) {
                angular.forEach($subjects, function (value, key) {
                    $scope.subjects[key] = value == 1 ? true : false;
                });
            };

            $scope.getPrettyCallBackTime = function () {
                if ($scope.intake_call.volunteer_schedule)
                    return moment($scope.intake_call.volunteer_schedule.start_time).format('MMM-D h:mm A') + ' - ' + moment($scope.intake_call.volunteer_schedule.end_time).format('h:mm A');
                return false;
            };

            //returns true if at least one subject is true
            $scope.SubjectHasTruth = function () {
                var result = false;
                angular.forEach($scope.subjects, function (value) {
                    if (value) {
                        result = true;
                    }
                });
                return result;
            };

            $scope.convertIntakeValuesToString = function () {
                // We need to convert the INT values to string, for SELECT OPTION
                var call_intake = $scope.intake_call;
                call_intake.how_ship = call_intake.how_ship ? call_intake.how_ship.toString() : '';
                call_intake.continuing_contact = call_intake.continuing_contact ? call_intake.continuing_contact.toString() : '';
                if (call_intake.age_group !== null) {
                    call_intake.age_group = call_intake.age_group.toString();
                }
                call_intake.gender = call_intake.gender ? call_intake.gender.toString() : '';
                call_intake.primary_language = call_intake.primary_language ? call_intake.primary_language.toString() : '';
                call_intake.income = call_intake.income ? call_intake.income.toString() : '';
                call_intake.assets = call_intake.assets ? call_intake.assets.toString() : '';
                call_intake.apply_receive_ssd_md = call_intake.apply_receive_ssd_md ? call_intake.apply_receive_ssd_md.toString() : '';
                call_intake.dual_eligible = call_intake.dual_eligible ? call_intake.dual_eligible.toString() : '';
                call_intake.contact = call_intake.contact ? call_intake.contact.toString() : '';
                call_intake.priority = call_intake.priority ? call_intake.priority.toString() : '';
            };
        }
    ]);
})(window.skeletonApp);
