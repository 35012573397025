(function (app) {
    'use strict';

    app.directive('phoneInput', function() {
        return {
            require: 'ngModel',
            link: function(scope, element, attrs, ngModelCtrl) {
                function formatPhoneNumber(value) {
                    if (!value) {
                        return value;
                    }

                    var formattedValue = value.replace(/\D/g, '');

                    if (formattedValue.length <= 3) {
                        formattedValue = formattedValue.replace(/^(\d{0,3})/, '($1');
                    } else if (formattedValue.length <= 6) {
                        formattedValue = formattedValue.replace(/^(\d{0,3})(\d{0,3})/, '($1) $2');
                    } else {
                        formattedValue = formattedValue.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3');
                    }

                    if (formattedValue !== value) {
                        ngModelCtrl.$setViewValue(formattedValue);
                        ngModelCtrl.$render();
                    }

                    return formattedValue;
                }

                ngModelCtrl.$parsers.push(formatPhoneNumber);
                ngModelCtrl.$formatters.push(formatPhoneNumber);

                element.bind('blur', function() {
                    var formattedValue = formatPhoneNumber(ngModelCtrl.$modelValue);
                    ngModelCtrl.$setViewValue(formattedValue);
                    ngModelCtrl.$render();
                });
            }
        };
    });

    app.controller('CallQueueCtrlAdmin', [
        '$scope', 'api', 'SweetAlert', '$routeParams', '$location', 'alerts', '$localStorage',
        function ($scope, api, SweetAlert, $routeParams, $location, alerts, $localStorage) {

            if (!$localStorage.hasOwnProperty('personal_view')) {
                $localStorage.personal_view = false;
            }
            $scope.all_calls = [];
            $scope.displayed = [];
            $scope.calls_type = $location.path().split('/').reverse()[0];
            $scope.isMyCalls = $location.path().indexOf('my-calls') >= 0;
            $scope.personal_view = $localStorage.personal_view;
            $scope.isLoading = false;
            $scope.allCallsView = false; // Add flag to track view state
            console.log('Current user:', $scope.current_user);

            $scope.getCalls = function (tablestate) {
                $localStorage.personal_view = $scope.personal_view;
                tablestate.isMyCalls = $scope.isMyCalls;
                tablestate.calls_type = $scope.calls_type;
                tablestate.personal_view = $scope.personal_view;
                $scope.tablestate = tablestate;
                $scope.isLoading = true;

                api.postSearchCalls(tablestate)
                    .then(function (results) {
                        console.log('Fetched calls:', results);
                        $scope.isLoading = false;
                        $scope.displayed = [].concat(results.calls);
                        $scope.call_count = results.total;
                        tablestate.pagination.numberOfPages = Math.ceil(results.total / tablestate.pagination.number);
                    })
                    .catch(function (results) {
                        SweetAlert.swal('Error', results, 'error');
                    });
            };

            $scope.getCompletedDate = function (date) {
                if (date == '0000-00-00 00:00:00') return 'Not Completed';
                return $scope.formatDate(date);
            };

            $scope.formatDate = function (date) {
                return moment(date).format('MM/DD/YYYY');
            };

            $scope.getStatus = function (status) {
                if (status === '1') return 'New';
                if (status === '2') return 'In Progress';
                if (status === '3') return '(Left Message)';
                if (status === '4') return 'Closed';
                if (status === '5') return '(Called Back)';
                if (status === '6') return '(Could Not Contact)';
                return '';
            };

            $scope.getContact = function (contact) {
                if (contact === '1') return '(Phone Call)';
                if (contact === '2') return '(Sent Email)';
                if (contact === '3') return '(Left Voicemail)';
                if (contact === '4') return '(Unable to Contact)';
                if (contact === '5') return '(Other)';
                return '';
            };

            $scope.viewCall = function (call_data) {
                console.log('viewCall called with call_data:', call_data);
                // Fetch the latest call data from the server
                api.getCallById(call_data.id)
                    .then(function (response) {
                        console.log('Fetched latest call data:', response.data);
                        $scope.call_data = response.data; // Update the scope with the latest data

                        // Log to verify data before opening modal
                        console.log('Updated scope call_data:', $scope.call_data);

                        alerts.callRegister($scope.call_data, $scope.current_user.volunteer.id, function ($uiModal, call_data) {
                            call_data.volunteer_id = $scope.current_user.volunteer.id;
                            api.postUpdateCallbackTime(call_data)
                                .then(function (response) {
                                    console.log('Call updated successfully');
                                    SweetAlert.swal('Success', 'You have registered to this call!', 'success');
                                    $uiModal.close();
                                    $scope.getCalls($scope.tablestate);
                                })
                                .catch(function (response) {
                                    SweetAlert.swal('Error', response, 'error');
                                });
                        });
                    })
                    .catch(function (response) {
                        SweetAlert.swal('Error', response, 'error');
                    });
            };

            $scope.viewCallLimited = function (call) {
                alerts.viewCall(call,
                    // Resolve the call
                    function (call_data, ui_model) {},
                    // Update the call
                    function (call_data, ui_model) {
                        console.log('Updating call data:', call_data);
                        var completed_date = moment().format();
                        if (['1', '2', '4'].indexOf(call_data.status) >= 0) {
                            completed_date = '0000-00-00 00:00:00';
                        }

                        api.postUpdateCall({
                            call: {
                                info: {
                                    id: call_data.id,
                                    notes: call_data.notes,
                                    issue_description: call_data.issue_description,
                                    time_spent: call_data.time_spent,
                                    completed_date: completed_date,
                                    status: call_data.status,
                                    age_group: call_data.age_group,
                                    caller_name: call_data.caller_name,
                                    caller_phone: call_data.caller_phone,
                                    address: call_data.address,
                                    caller_city: call_data.caller_city,
                                    zip: call_data.zip,
                                    caller_county_id: call_data.caller_county_id,
                                    site_coordinator_id: call_data.site_coordinator_id,
                                    contact: call_data.contact // Include contact here
                                }
                            }
                        })
                        .then(function (results) {
                            console.log('Call updated:', results);
                            ui_model.close();
                            $scope.getCalls($scope.tablestate);
                            SweetAlert.swal('Success', 'The call has been updated!', 'success');
                        })
                        .catch(function (results) {
                            SweetAlert.swal('Error', results.error, 'error');
                        });
                    }
                );
            };

            $scope.openCall = function (call) {
                var originalCall = {};
                angular.copy(call, originalCall); // Save original call data
                var temp_call = {};
                angular.copy(call, temp_call);
                console.log('Opening call:', temp_call);
                
                // MARK AS VIEWED
                api.postUpdateCall({
                    call: {
                        info: {
                            id: temp_call.id,
                            has_alert: false
                        }
                    }
                })
                .then(function (results) {
                    console.log('Marked as viewed:', results);
                    call.has_alert = false;
                })
                .catch(function (results) {
                    SweetAlert.swal('Error', results, 'error');
                });
            
                alerts.viewCall(temp_call,
                    // Resolve the call
                    function (call_data, ui_model) {
                        api.postUpdateCall({
                            call: {
                                info: {
                                    id: call_data.id,
                                    status: 'r',
                                    has_alert: false
                                }
                            }
                        })
                        .then(function (results) {
                            console.log('Call resolved:', results);
                            $scope.getCalls($scope.tablestate);
                            SweetAlert.swal('Success', 'The call has been marked as resolved!', 'success');
                            ui_model.dismiss();
                        })
                        .catch(function (results) {
                            SweetAlert.swal('Error', results, 'error');
                        });
                    },
                    // Unvolunteer from call
                    function (id, ui_model) {
                        api.postRemoveVolunteer(id).then(function () {
                            console.log('Volunteer removed:', id);
                            $scope.getCalls($scope.tablestate);
                            ui_model.close();
                        });
                    },
                    // Update the call
                    function (call_data, ui_model) {
                        console.log('Updating call data:', call_data);
                        var completed_date = moment().format();
                        if (['1', '2', '3'].indexOf(call_data.status) >= 0) {
                            completed_date = '0000-00-00 00:00:00';
                        }
            
                        // Preserve existing values for 'reason for call' and 'notes on call' if not provided
                        call_data.issue_description = call_data.issue_description || originalCall.issue_description;
                        call_data.notes = call_data.notes || originalCall.notes;
            
                        api.postUpdateCall({
                            call: {
                                info: {
                                    id: call_data.id,
                                    notes: call_data.notes,
                                    issue_description: call_data.issue_description,
                                    time_spent: call_data.time_spent,
                                    completed_date: completed_date,
                                    status: call_data.status,
                                    has_alert: false,
                                    age_group: call_data.age_group,
                                    caller_name: call_data.caller_name,
                                    caller_phone: call_data.caller_phone,
                                    address: call_data.address,
                                    caller_city: call_data.caller_city,
                                    zip: call_data.zip,
                                    caller_county_id: call_data.caller_county_id,
                                    site_coordinator_id: call_data.site_coordinator_id,
                                    contact: call_data.contact // Include contact here
                                }
                            }
                        })
                        .then(function (results) {
                            console.log('Call updated:', results);
                            $scope.getCalls($scope.tablestate);
                            ui_model.close();
                            SweetAlert.swal('Success', 'The call has been updated!', 'success');
                        })
                        .catch(function (results) {
                            SweetAlert.swal('Error', results.error, 'error');
                        });
                    },
                    // Handle modal close without saving
                    function (ui_model) {
                        angular.copy(originalCall, call); // Revert to original call data
                        ui_model.close();
                    }
                );
            };

            $scope.viewAllCalls = function() {
                $scope.isLoading = true;
                $scope.allCallsView = true; // Set flag to true when viewing all calls
                var tablestate = {
                    pagination: {
                        start: 0,
                        number: 100 // or whatever number of items you want to fetch
                    },
                    sort: {
                        predicate: 'call_date_received',
                        reverse: false
                    },
                    search: {}
                };
                api.postSearchCalls(tablestate)  // Pass tablestate with pagination
                .then(function(results) {
                    $scope.isLoading = false;
                    $scope.displayed = [].concat(results.calls);
                })
                .catch(function(results) {
                    $scope.isLoading = false;
                    SweetAlert.swal('Error', results, 'error');
                });
            };

            $scope.viewPendingCalls = function() {
                $scope.isLoading = true;
                $scope.allCallsView = false; // Set flag to false when viewing pending calls
                $scope.getCalls($scope.tablestate); // Fetch pending calls
            };
        }
    ]);
})(window.skeletonApp);
