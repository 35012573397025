'use strict';
(function (app) {
    app.controller('VolunteerHomeCtrl', [
        '$scope', 'api', 'SweetAlert',
        function ($scope, api, SweetAlert) {
            $scope.all_events = [];
            $scope.my_calls = [];
            $scope.has_calls_today = false;

            (function () {
                // Check impersonation state
                api.getImpersonationState().then(function (response) {
                    if (response && typeof response.isImpersonating !== 'undefined') {
                        $scope.isImpersonating = response.isImpersonating;
                    } else {
                        console.error('Unexpected response structure:', response);
                    }
                }).catch(function (error) {
                    SweetAlert.swal('Error', 'Unable to get impersonation state!', 'error');
                });

                // Get Calls
                api.getCallByVolunteerId($scope.current_user.id)
                    .then(function (response) {
                        if (response.has_alert) {
                            SweetAlert.swal('Attention', 'A call requires your attention!', 'warning');
                        }
                        // Only show blocks with calls
                        if (response.calls && response.calls.length > 0) {
                            for (var i = 0; i < response.calls.length; i++) {
                                var call = response.calls[i];
                                if (call.status == 1) {
                                    $scope.has_calls_today = true;
                                    $scope.my_calls.push(call);
                                }
                            }
                        }
                    })
                    .catch(function (response) {
                        SweetAlert.swal('Error', 'There was an error obtaining your calls! Please try again!', 'error');
                    });

                // Get all events
                api.getAllEvents({ start_time: moment().format(), end_time: moment().endOf('month').format() })
                    .then(function (response) {
                        angular.forEach(response.events, function (event) {
                            event.date = moment(event.date).format('MMMM D, h:mm A');
                            for (var i = 0; i < event.event_blocks.length; i++) {
                                var event_block = event.event_blocks[i];
                                if ((event_block.volunteers_needed <= event_block.event_volunteers.length) && event.full)
                                    event.full = true;
                                else
                                    event.full = false;
                                for (var ii = 0; ii < event_block.event_volunteers.length; ii++) {
                                    if (event_block.event_volunteers[ii].volunteer_id == $scope.current_user.volunteer.id) {
                                        event.registered = true;
                                    }
                                }
                            }
                            if (!event.registered)
                                $scope.all_events.push(event);
                        });
                    })
                    .catch(function (response) {
                        SweetAlert.swal('Error', 'There was an error obtaining events! Please try again!', 'error');
                    });

                // User registered events
                if ($scope.current_user && $scope.current_user.volunteer) {
                    api.getAllVolunteerRegisteredEvents($scope.current_user.volunteer.id)
                        .then(function (response) {
                            $scope.all_my_events = response.events;
                            for (var i = 0; i < $scope.all_my_events.length; i++) {
                                var event = $scope.all_my_events[i];
                                event.date = moment(event.date).format('MMMM D, h:mm A');
                                for (var ii = 0; ii < event.my_event_blocks.length; ii++) {
                                    event.my_event_blocks[ii].start_time = moment(event.my_event_blocks[ii].start_time).format('h:mm A');
                                    event.my_event_blocks[ii].end_time = moment(event.my_event_blocks[ii].end_time).format('h:mm A');
                                }

                                $scope.all_my_events[i] = event;
                            }
                        })
                        .catch(function (response) {
                            SweetAlert.swal('Error', response, 'error');
                        });
                }
            })();

            $scope.openGoogleMaps = function (location_name, location_address) {
                window.open('https://maps.google.com/?q=' + location_name + ',' + location_address, '_blank', '');
            };

            // Stop impersonating user
            $scope.stopImpersonating = function () {
                api.stopImpersonating().then(function (response) {
                    if (response && response.success) {
                        SweetAlert.swal('Success', response.message || 'You have stopped impersonating.', 'success');
                        $scope.isImpersonating = false;
                        location.reload();
                    } else {
                        SweetAlert.swal('Error', response.message || 'Unable to stop impersonating user!', 'error');
                    }
                }).catch(function (error) {
                    SweetAlert.swal('Error', 'Unable to stop impersonating user!', 'error');
                    console.error('Stop impersonating error:', error);
                });
            };
        }
    ]);
})(window.skeletonApp);

