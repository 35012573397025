/**
 * Created by mwillard on 5/20/16.
 */
(function(app)
{
    app.controller('VolunteerTimeEditorCtrl', [
        '$scope','api','SweetAlert','$routeParams', '$location', 'alerts', 'uiCalendarConfig',
        function($scope, api, SweetAlert, $routeParams,$location, alerts,uiCalendarConfig)
        {
            if($routeParams.volunteer_id)
                $scope.volunteer_id = $routeParams.volunteer_id;

            $scope.users = [];
            $scope.displayed = [];
            $scope.users_per_page = 5;
            $scope.isLoading = false;
            $scope.user_type_search = '';
            $scope.$watch('user_type_search', function()
            {
                $scope.getUsers($scope.tablestate);
            });
            $scope.getUsers = function(tablestate)
            {
                $scope.isLoading = true;

                if(!tablestate)
                    return;
                tablestate.search.user_type = 'volunteer';
                $scope.tablestate = tablestate;
                api
                    .postSearchUsers(tablestate)
                    .then(function(results)
                    {
                        (tablestate);
                        $scope.isLoading = false;
                        $scope.displayed = [].concat(results.users);
                        tablestate.pagination.numberOfPages = Math.ceil(results.total / tablestate.pagination.number);
                    })
                    .catch(function(results)
                    {
                        SweetAlert.swal('Error',results,'error');
                    });

            };
            $scope.viewUser = function(volunteer_id)
            {
                $location.path('/users/edit-time/' + volunteer_id);
            };
            $scope.myTime =[];
            $scope.volunteer_schedules = {};


            $scope.getSchedule = function(start, end, timezone, callback)
            {
                $scope.schedule_info = {start:start, end:end, timezone:timezone, callback:callback};
                api
                    .getScheduleByVolunteer({id:$scope.volunteer_id, start:start.format(), end:end.format()})
                    .then(function(response)
                    {

                        for (var i = 0; i < $scope.myTime.length; i++) $scope.myTime.splice(i);

                        angular.forEach(response.volunteer_schedule, function (value, key) {

                            $scope.myTime.push(
                                {
                                    id: value.id,
                                    calls:value.calls,
                                    start: value.start_time.date,
                                    color:value.calls.length ? '#4CAF50' : '#039BE5',
                                    end: value.end_time.date
                                });
                        });
                        callback($scope.myTime);
                    })
                    .catch(function(response)
                    {
                        SweetAlert.swal('Error', 'There was an issue grabbing your schedule! Please Try Again!', 'error');
                    });
            };
            $scope.refreshEvents = function() { uiCalendarConfig.calendars.myCalendar.fullCalendar('refetchEvents'); };
            $scope.AddEvent = function(time)
            {
                //Did we click a date on the calendar? If not we need time time now
                if(time)
                {
                    if(time.isMoment)
                        $scope.volunteer_schedules.start_time = new Date(time.format("M/D/Y, h:mm A"))
                    else
                        $scope.volunteer_schedules.start_time = new Date(moment(time).format('M/D/Y h:mm A'));
                }
                else
                    $scope.volunteer_schedules.start_time = new Date(moment().format('M/D/Y h:mm A'));

                //Alert time set
                alerts.timeSet(false, $scope.volunteer_schedules, function(response)
                {
                    $scope.volunteer_schedules = response;

                    api
                        .postAddVolunteerSchedule(
                            {
                                start_time:moment($scope.volunteer_schedules.start_time).format(),
                                hours:$scope.volunteer_schedules.hours,
                                volunteer_id:$scope.volunteer_id
                            })
                        .then(function(response)
                        {
                            SweetAlert.swal('Success','Volunteer time saved!','success');
                            $scope.refreshEvents();
                        })
                        .catch(function(response)
                        {
                            SweetAlert.swal('Error', response ,'error');
                            $scope.AddEvent($scope.volunteer_schedules.start_time);
                        });

                }, true);
            };

            $scope.RemoveEvent = function(data,uiModal)
            {
                SweetAlert.swal(
                    {
                        title:"Are you sure?",
                        text:"Remove this from the volunteer's schedule? Any assigned calls will be changed to 'unassigned'.",
                        type:"warning",
                        showCancelButton:true,
                        confirmButtonColor: "#DD6B55",
                        confirmButtonText: "Yes, remove it",
                        closeOnConfirm: false,
                        closeOnCancel:  true,
                    },
                    function(confirm){
                        if(confirm)
                            api
                                .postDeleteVolunteerSchedule(
                                    {
                                        id:data.id,
                                        admin:true
                                    })
                                .then(function(response)
                                {
                                    if(response.error)
                                    {
                                        SweetAlert.swal('Error', 'There was an error!\n' + response.error, 'error');
                                    }
                                    else
                                    {
                                        SweetAlert.swal('Success', 'Successfully removed!', 'success');
                                        uiModal.dismiss();
                                        $scope.refreshEvents();
                                    }
                                })
                                .catch(function(response)
                                {
                                    SweetAlert.swal('Error', 'There was an error!\n' + response, 'error');
                                })
                    });
            };

            $scope.EditEvent = function(time)
            {
                //time = the data returned from full calender

                var data = {
                    id:time.id,
                    calls:time.calls
                };

                //Check if this is returned from an error!
                if(time.hours)
                {
                    data.hours = time.hours;
                    data.start_time = time.start_time;
                    data.end_time = time.end_time;
                }
                else
                {
                    data.hours = moment.duration(time.end.diff(time.start)).asHours();
                    data.start_time = new Date(time.start.format("M/D/Y, h:mm A"));
                    data.end_time = time.end.format("M/D/Y, h:mm A");
                }
                alerts.timeSet(true,data
                    ,function(response)
                    {
                        api
                            .postUpdateVolunteerSchedule(
                                {
                                    start_time:moment(response.start_time).format(),
                                    hours:response.hours,
                                    id:time.id,
                                    admin:true
                                })
                            .then(function(response)
                            {
                                if(response.error)
                                {
                                    SweetAlert.swal('error', 'There was an error updating your time!\n' + response.error, 'error');
                                    $scope.EditEvent(data);
                                }
                                else {
                                    $scope.refreshEvents();
                                    SweetAlert.swal("Success", "Volunteer time updated!", "success");
                                }
                            })
                            .catch(function(response)
                            {
                                SweetAlert.swal("Error"
                                    , "There was an error updating your time!\n" + response
                                    , "error");
                                $scope.EditEvent(data);
                            });
                    },$scope.RemoveEvent, true);
            };
            $scope.uiConfig =
            {
                calendar: {
                    defaultView:"agendaWeek",
                    editable: false,
                    selectable:false,
                    eventLimit:true,
                    allDaySlot:false,
                    height:680,
                    minTime:"08:00:00",
                    maxTime:"20:00:00",
                    header: {

                    },
                    events:$scope.getSchedule,
                    loading:function(loading){$scope.loading_time = loading},
                    eventClick:$scope.EditEvent
                },
            };

        }
    ])
})(window.skeletonApp);
